<template>
  <div>
    <!-- 発注検索（東北商品センター）画面 -->
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-expansion-panels accordion focusable v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>発注検索（東北商品センター）</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text>
                    <v-form ref="formSearchConditionsOrder">
                      <v-row>
                        <!-- 作成日付 -->
                        <v-col cols="12" sm="4">
                          <v-menu
                            v-model="scOrderDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            left
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scOrderDateRange"
                                label="作成日付"
                                append-icon="mdi-calendar"
                                readonly
                                clearable
                                persistent-hint
                                :hint="`完全一致/範囲検索`"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="scOrderDates = []"
                                @click:append="scOrderDateMenu = !scOrderDateMenu"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="scOrderDates"
                              range
                              @input="scOrderDateMenu = false"
                              :day-format="(date) => new Date(date).getDate()"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- 着日 -->
                        <v-col cols="12" sm="3">
                          <v-menu
                            v-model="scInDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            left
                            min-width="auto"
                            :disabled="inDateUndecidedCheckBox"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scInDateRange"
                                label="着日"
                                append-icon="mdi-calendar"
                                readonly
                                clearable
                                persistent-hint
                                :hint="`完全一致/範囲検索`"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="scInDates = []"
                                @click:append="scInDateMenu = !scInDateMenu"
                                :disabled="inDateUndecidedCheckBox"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="scInDates"
                              range
                              @input="scInDateMenu = false"
                              :day-format="(date) => new Date(date).getDate()"
                              :disabled="inDateUndecidedCheckBox"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="1">
                          <!-- 着日未定 -->
                          <v-checkbox
                            v-model="inDateUndecidedCheckBox"
                            :label="`未定`"
                            dense
                            hide-details="false"
                          ></v-checkbox>
                        </v-col>
                        <!-- 注文番号開始 -->
                        <v-col cols="12" sm="2">
                          <v-text-field
                            v-model="scOrderNoStart"
                            label="注文番号開始"
                            :rules="[number, limit_length5]"
                            counter="5"
                            clearable
                            persistent-hint
                            :hint="`完全一致/範囲検索`"
                            hide-spin-buttons
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 注文番号終了 -->
                        <v-col cols="12" sm="2">
                          <v-text-field
                            v-model="scOrderNoEnd"
                            label="注文番号終了"
                            :rules="[number, limit_length5]"
                            counter="5"
                            clearable
                            hide-spin-buttons
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 営業所名 -->
                        <v-col cols="12" sm="3">
                          <v-text-field
                            v-model="scSalesOfficeName"
                            label="営業所名"
                            :rules="[limit_length50]"
                            counter="50"
                            clearable
                            persistent-hint
                            :hint="`部分一致`"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- CSV出力日付 -->
                        <v-col cols="12" sm="3">
                          <v-menu
                            v-model="scCsvOutputDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            left
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="scCsvOutPutRange"
                                label="CSV出力日付"
                                append-icon="mdi-calendar"
                                readonly
                                clearable
                                persistent-hint
                                :hint="`完全一致/範囲検索`"
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="scCsvOutPutDates = []"
                                @click:append="scCsvOutputDateMenu = !scCsvOutputDateMenu"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="scCsvOutPutDates"
                              range
                              @input="scCsvOutputDateMenu = false"
                              :day-format="(date) => new Date(date).getDate()"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <!-- 仕入先コード -->
                        <v-col cols="12" sm="3">
                          <v-autocomplete
                            v-model="scSupplierCode"
                            label="仕入先コード"
                            :items="supplierList"
                            item-text="comboItem"
                            item-value="code"
                            clearable
                            persistent-hint
                            :hint="`完全一致`"
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- 仕入先伝票番号 -->
                        <v-col cols="12" sm="3">
                          <v-text-field
                            v-model="scPurchaseSlipNo"
                            label="仕入先伝票番号"
                            :rules="[limit_length10]"
                            counter="10"
                            clearable
                            persistent-hint
                            :hint="`完全一致`"
                          >
                          </v-text-field>
                        </v-col>
                        <!-- 仙台入荷 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scSendaiInFlgRadioGroup"
                            label="仙台入荷"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 仙南入荷 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scSennanInFlgRadioGroup"
                            label="仙南入荷"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 仙北入荷 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scSenbokuInFlgRadioGroup"
                            label="仙北入荷"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 仮伝 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scTempSlipFlgRadioGroup"
                            label="仮伝"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- 本伝 -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scSlipFlgRadioGroup"
                            label="本伝"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                        <!-- キャンセル -->
                        <v-col cols="12" sm="2">
                          <v-radio-group
                            v-model="scCancelRadioGroup"
                            label="キャンセル"
                            mandatory
                            dense
                            hide-details="false"
                          >
                            <v-radio label="全部" value="0"></v-radio>
                            <v-radio label="チェック" value="1"></v-radio>
                            <v-radio label="なし" value="2"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-5"></v-divider>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-row align="center" justify="center">
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <!-- 検索 -->
                        <v-btn
                          color="secondary"
                          v-on:click="onClickSearchOrderData"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-magnify </v-icon>検索
                        </v-btn>
                      </v-col>
                      <!-- EXCEL出力ボタン -->
                      <!-- ●TODO 未保存であれば警告かエラー -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          color="secondary"
                          v-on:click="outputCenterExcel"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-microsoft-excel </v-icon>EXCEL出力
                        </v-btn>
                      </v-col>
                      <!-- 仕入CSV出力 -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          target="_blank"
                          color="secondary"
                          v-on:click="onClickPurchaseCsv"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-file-delimited </v-icon>仕入CSV出力
                        </v-btn>
                      </v-col>
                      <!-- トシン伝票明細出力 -->
                      <v-col
                        cols="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        xl="auto"
                      >
                        <v-btn
                          color="secondary"
                          v-on:click="outputToshinSalesSlip"
                          rounded
                          class="my-2"
                        >
                          <v-icon left> mdi-file-pdf-box </v-icon>トシン伝票明細出力
                        </v-btn>
                      </v-col>
                      <v-dialog v-model="toshinDataNotExistDialog" max-width="500px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">確認</span>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-container>
                              出力対象のデータが存在しませんでした
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="secondary" rounded @click="toshinDataNotExistDialog = false"> OK </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        <!-- 商品登録モーダル -->
          <template v-if="dialogProductInsert">
            <v-dialog
              v-model="dialogProductInsert"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar dark color="primary">
                  <v-btn
                    icon
                    dark
                    @click="response(null)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-row>
                  <v-col>
                    <!-- 商品登録エリア（モーダル共通） -->
                    <ProductInsertArea
                      :transitionFlg="true"
                      :targetBranchNo="targetBranchNo"
                      :editProductCode="editProductCode"
                      @result="response"
                    ></ProductInsertArea>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </template>
          <!-- 発注明細データエリア -->
          <v-form ref="formDetails">
            <v-data-table
              dense
              :headers="headers"
              :items="searchItemList"
              :sort-by="['orderDate']"
              :sort-desc="[true]"
              :footer-props="footerProps"
              :items-per-page="100"
              class="elevation-5 mt-5"
              :item-class="rowClasses"
              v-model="selected"
              show-select
              item-key="tableKey"
              multi-sort
              fixed-header
              height="545"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-container>
                    <v-row>
                      <v-toolbar-title class="pt-4">検索結果</v-toolbar-title>
                      <v-divider class="mx-4 pt-4" inset vertical></v-divider>
                      <v-toolbar-title class="pt-4">
                        {{ searchItemList.length }}件
                      </v-toolbar-title>
                      <div class="ml-4">
                        <v-autocomplete
                          v-model="filterValue"
                          label=" 絞り込み"
                          :items="filterList"
                          item-text="comboItem"
                          item-value="code"
                          @change="updateSummaryCombo"
                          hide-details="false"
                        >
                        </v-autocomplete>
                      </div>
                    </v-row>
                  </v-container>
                </v-toolbar>
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>修正</span>
                </v-tooltip>
              </template>
              <!-- 仕入先名 -->
              <template v-slot:[`item.supplierName`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="nameOmit">
                      {{item.supplierName}}</div>
                  </template>
                  <span>{{item.supplierName}}</span>
                </v-tooltip>
              </template>
              <!-- 品名 -->
              <template v-slot:[`item.productName`]="{ item }">
                <v-row>
                  <v-col cols="10">
                    <v-edit-dialog
                      :return-value.sync="item.productName"
                      large
                      cancel-text="キャンセル"
                      save-text="OK"
                      @save="save(item)"
                      @cancel="cancel"
                    >
                      <v-chip link class="productNameOmit">{{ item.productName }}</v-chip>
                      <template v-slot:input>
                        <div class="mt-4 text-h6">品名</div>
                        <v-text-field
                          v-model="item.productName"
                          id="productName"
                          :rules="[limit_lengthProductName]"
                          single-line
                          counter="40"
                          :counter-value="productCount"
                          autofocus
                          clearable
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </v-col>
                  <v-col cols="2" class="productEditIconLayout">
                    <v-icon
                      color="secondary"
                      @click="callEditProduct(item)"
                    >mdi-database-edit</v-icon>
                  </v-col>
                </v-row>
              </template>
              <!-- 数量 -->
              <template v-slot:[`item.amount`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.amount"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.amount }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">数量</div>
                    <v-text-field
                      v-model.number="item.amount"
                      min="0"
                      id="amount"
                      :rules="[limit_length9, numberRequired, number]"
                      single-line
                      counter="9"
                      :counter-value="numCount"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 単位 -->
              <template v-slot:[`item.unit`]="{ item }">
                <v-edit-dialog
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @open="openUnit(item)"
                  @save="saveUnit(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.unit }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">単位</div>
                    <v-combobox
                      v-model="viewUnit"
                      id="unit"
                      :items="unitList"
                      :rules="[limit_length10]"
                      counter="10"
                      clearable
                      autofocus
                      hide-selected
                      ref="unitComboBox"
                    >
                    </v-combobox>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 仕入先伝票番号 -->
              <template v-slot:[`item.purchaseSlipNo`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.purchaseSlipNo"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.purchaseSlipNo }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">仕入先伝票番号</div>
                    <v-text-field
                      v-model="item.purchaseSlipNo"
                      id="purchaseSlipNo"
                      :rules="[limit_length10]"
                      single-line
                      counter="10"
                      autofocus
                      clearable
                      @change="purchaseSlipNo(item)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- NET -->
              <template v-slot:[`item.net`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.net"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="calcNet(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.net==null || item.net==``">{{item.net}}</div>
                    <div v-else>{{ new Number(item.net).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">NET</div>
                    <v-text-field
                      v-model.number="item.net"
                      id="net"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- キャンセル-->
              <template v-slot:[`item.cancelFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.cancelFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仕入CSV -->
              <template v-slot:[`item.purchaseCsvFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.purchaseCsvFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 入荷時仕入価格 -->
              <template v-slot:[`item.arrivalPurchasePrice`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.arrivalPurchasePrice"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.arrivalPurchasePrice==null || item.arrivalPurchasePrice==``">{{item.arrivalPurchasePrice}}</div>
                    <div v-else>{{ new Number(item.arrivalPurchasePrice).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">入荷時仕入価格</div>
                    <v-text-field
                      v-model.number="item.arrivalPurchasePrice"
                      id="arrivalPurchasePrice"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 最終仕入価格 -->
              <template v-slot:[`item.lastCost`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.lastCost"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.lastCost==null || item.lastCost==``">{{item.lastCost}}</div>
                    <div v-else>{{ new Number(item.lastCost).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">最終仕入価格</div>
                    <v-text-field
                      v-model.number="item.lastCost"
                      id="lastCost"
                      :rules="[price]"
                      single-line
                      counter="11"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 営業所名 -->
              <template v-slot:[`item.salesOfficeName`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.salesOfficeName"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="changeSalesOfficeNameList(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.salesOfficeName }}</v-chip>
                    <template v-slot:input>
                    <div class="mt-4 text-h6">営業所名</div>
                  <v-autocomplete
                    v-model="item.salesOfficeName"
                    id="salesOfficeName"
                    :rules="[required]"
                    :items="salesOfficeNameList"
                    item-text="comboItem"
                    item-value="name"
                    single-line
                    autofocus
                  ></v-autocomplete>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 仙台納品数 -->
                <template v-slot:[`item.sendaiDelivery`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.sendaiDelivery"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="totalAmount(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>
                      {{ item.sendaiDelivery }}
                    </v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">仙台納品数</div>
                      <v-text-field
                        v-model.number="item.sendaiDelivery"
                        id="sendaiAmount"
                        :rules="[limit_length9, number]"
                        single-line
                        counter="9"
                        :counter-value="numCount"
                        clearable
                        autofocus
                        type="number"
                        hide-spin-buttons
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 仙南納品数 -->
                <template v-slot:[`item.sennanDelivery`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.sennanDelivery"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="totalAmount(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>
                      {{ item.sennanDelivery }}
                    </v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">仙南納品数</div>
                      <v-text-field
                        v-model.number="item.sennanDelivery"
                        id="sennanAmount"
                        :rules="[limit_length9, number]"
                        single-line
                        counter="9"
                        :counter-value="numCount"
                        clearable
                        autofocus
                        type="number"
                        hide-spin-buttons
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <!-- 仙北納品数 -->
                <template v-slot:[`item.senbokuDelivery`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.senbokuDelivery"
                    large
                    cancel-text="キャンセル"
                    save-text="OK"
                    @save="totalAmount(item)"
                    @cancel="cancel"
                  >
                    <v-chip link>
                      {{ item.senbokuDelivery }}
                    </v-chip>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">仙北納品数</div>
                      <v-text-field
                        v-model.number="item.senbokuDelivery"
                        id="senbokuAmount"
                        :rules="[limit_length9, number]"
                        single-line
                        counter="9"
                        :counter-value="numCount"
                        clearable
                        autofocus
                        type="number"
                        hide-spin-buttons
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              <!-- 着日 -->
              <template v-slot:[`item.inDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.inDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.inDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">着日</div>
                    <v-menu
                      v-model="item.inDateSelectMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.inDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.inDateSelectMenu = !item.inDateSelectMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.inDate"
                        @input="item.inDateSelectMenu = !item.inDateSelectMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 着日未定 -->
              <template v-slot:[`item.inDateUndecidedFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.inDateUndecidedFlg"
                  color="secondary"
                  :ripple="false"
                ></v-simple-checkbox>
              </template>
              <!-- 当 -->
              <template v-slot:[`item.inDateToday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDateToday(item, 0)"
                    >
                      当
                    </v-btn>
                  </template>
                  <span>着日に当日日付をセットします</span>
                </v-tooltip>
              </template>
              <!-- 翌 -->
              <template v-slot:[`item.inDateNextday`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="setDateNextday(item, 0)"
                    >
                      翌
                    </v-btn>
                  </template>
                  <span>着日に翌日日付をセットします</span>
                </v-tooltip>
              </template>
              <!-- 同注番Copy -->
              <template v-slot:[`item.copySameOrderInDate`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="m-0"
                      fab
                      dark
                      x-small
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyInDateToSameOrder(item)"
                    >
                    <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>同じ注番のデータに着日にコピーします</span>
                </v-tooltip>
              </template>
              <!-- 備考 -->
              <template v-slot:[`item.detailsDescription`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.detailsDescription"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.detailsDescription }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">備考</div>
                    <v-text-field
                      v-model="item.detailsDescription"
                      id="detailsDescription"
                      :rules="[limit_length50]"
                      single-line
                      counter="50"
                      autofocus
                      clearable
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 定価 -->
              <template v-slot:[`item.listPrice`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.listPrice"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div v-if="item.listPrice==null || item.listPrice==``">{{item.listPrice}}</div>
                    <div v-else>{{ new Number(item.listPrice).toLocaleString() }}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">定価</div>
                    <v-text-field
                      v-model.number="item.listPrice"
                      id="listPrice"
                      :rules="[number, limit_length9]"
                      single-line
                      counter="9"
                      :counter-value="numCount"
                      clearable
                      ref="moneyInput"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 仙台入荷 -->
              <template v-slot:[`item.sendaiInFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.sendaiInFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仙南入荷 -->
              <template v-slot:[`item.sennanInFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.sennanInFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仙北入荷 -->
              <template v-slot:[`item.senbokuInFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.senbokuInFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仮伝 -->
              <template v-slot:[`item.tempSlipFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.tempSlipFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 本伝 -->
              <template v-slot:[`item.slipFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.slipFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- トシン経由区分 -->
              <template v-slot:[`item.toshinSection`]="{ item }">
                <v-simple-checkbox
                  v-model="item.toshinSection"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- トシン伝票日付 -->
              <template v-slot:[`item.toshinSplitDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.toshinSplitDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="toshinSplitDate(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.toshinSplitDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">トシン伝票日付</div>
                    <v-menu
                      v-model="item.toshinSplitDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.toshinSplitDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.toshinSplitDateMenu = !item.toshinSplitDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.toshinSplitDate"
                        @input="item.toshinSplitDateMenu = !item.toshinSplitDateMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- トシン伝票番号 -->
              <template v-slot:[`item.toshinSplitNo`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.toshinSplitNo"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.toshinSplitNo }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">トシン伝票番号</div>
                    <v-text-field
                      v-model="item.toshinSplitNo"
                      id="toshinSplitNo"
                      :rules="[limit_length20]"
                      single-line
                      counter="20"
                      autofocus
                      clearable
                      @change="toshinSplitNo(item)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- トシン伝票印刷済 -->
              <template v-slot:[`item.toshinSplitPrintFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.toshinSplitPrintFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeToshinSplitPrintFlg(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 返品 -->
              <template v-slot:[`item.returnFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 値引 -->
              <template v-slot:[`item.discountFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.discountFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 良品/不良品 -->
              <template v-slot:[`item.returnStatus`]="{ item }">
                <v-radio-group
                  v-model="item.returnStatus"
                  label=""
                  row
                  dense
                  disabled
                >
                  <v-radio
                    v-for="returnStatus in returnStatusList"
                    :key="returnStatus.id"
                    :label="returnStatus.name"
                    :value="returnStatus.id"
                  ></v-radio>
                </v-radio-group>
              </template>
              <!-- 引上 -->
              <template v-slot:[`item.pullUpFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.pullUpFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 依頼 -->
              <template v-slot:[`item.returnRequestFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnRequestFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 返却 -->
              <template v-slot:[`item.returnProductFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnProductFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 仕入赤伝 -->
              <template v-slot:[`item.purchaseAkaCsvOutputFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.purchaseAkaCsvOutputFlg"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 仕入待ち -->
              <template v-slot:[`item.waitingPurchaseFlg`]="{ item }">
                <v-simple-checkbox
                  v-model="item.waitingPurchaseFlg"
                  color="secondary"
                  :ripple="false"
                  @input="changeIsUpdatedItem(item)"
                ></v-simple-checkbox>
              </template>
              <!-- 納品書日付 -->
              <template v-slot:[`item.deliveryDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.deliveryDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="deliveryDate(item)"
                  @cancel="cancel"
                >
                  <v-chip link>{{ item.deliveryDate }}</v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">納品書日付</div>
                    <v-menu
                      v-model="item.deliveryDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.deliveryDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.deliveryDateMenu = !item.deliveryDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.deliveryDate"
                        @input="item.deliveryDateMenu = !item.deliveryDateMenu"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-card-actions>
          <!-- 保存ボタン -->
          <v-btn color="secondary" @click="onClickRegister" rounded>
            <v-icon left> mdi-content-save </v-icon>保存
          </v-btn>
        </v-card-actions>
      </v-row>
      <v-spacer></v-spacer>
    </v-container>
  </div>
</template>
<script>
import ProductInsertArea from "../components/ProductInsertArea.vue";
import {/*  Auth, Amplify*/API, graphqlOperation, Storage } from "aws-amplify";
import {
  getM_SHOKISETTEI,
  listV_CENTER_HACCHU_DATA_WITH_MEISAI,
  getM_SHIIRESAKI,
  listM_SHIIRESAKI,
} from "@/graphql/queries";
import {
  executeHanbaikanriSql,
  executeTransactSql,
  executeExcelFileCreation,
 } from "@/graphql/mutations";
import { RETURN_PRODUCT_STATUS, CUSTOM_FOOTER_PROPS_SEARCH_AREA, RESULT_ROWS_COUNT_LIMIT } from "@/assets/js/const";
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_SAVE_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_COMPLETE_CRUD_DATA, ALERT_UNSAVED_DATA, ALERT_SEARCH_ZERO_DATA, ALERT_ALREADY_SAVED_DATA, ALERT_NO_CSV_DATA, ALERT_SUNELE_EDIT, ALERT_ROWS_COUNT_LIMIT } from "@/assets/js/dialog_messages";
import { base64Encode } from '@/assets/js/common';
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "CenterOrderSearch",
  components: {
    ProductInsertArea,
  },
  props: {
    reqOrderDataId: Number,
    // 商品検索・商品登録モーダル返却値 - 商品コード
    returnProductCode: Number,
    // モーダルでの呼び出し状態
    transitionFlg: Boolean,
  },
  data: () => ({
    filterValue: 0,
    filterList: [
      { comboItem: "全部", code: 0 },
      { comboItem: "通常", code: 8 },
      { comboItem: "数量", code: 1 },
      { comboItem: "納期", code: 2 },
      { comboItem: "金額", code: 3 },
      { comboItem: "入荷", code: 4 },
      { comboItem: "仕入", code: 5 },
      { comboItem: "トシン", code: 6 },
      { comboItem: "返品", code: 7 },
    ],

    // 発注明細VIEWデータリスト
    // 画面上の設定値（発注明細VIEWデータ）
    originalParamsViews: {
      // 発注データID
      orderDataId: null,
      // 部門コード
      departmentCode: null,
      // パナソニック区分
      panasonicSection: null,
      // 注文番号
      orderNo: null,
      // 注文日付
      orderDate: null,
      // 仕入先コード
      supplierCode: null,
      // 仕入先名
      supplierName: null,
      // 手数料区分
      commissionSection: null,
      // トシン経由区分
      toshinSection: 0,
      // トシンお客様コード
      toshinClientNo: null,
      // トシン受発注番号
      toshinOrderNo: null,
      // トシン発注者注文番号
      toshinManagerOrderNo: null,
      // 担当者コード
      managerCode: null,
      // 担当者名
      managerName: null,
      // 摘要
      description: null,
      // 明細No
      orderBranchNo: null,
      // 商品コード
      productCode: null,
      // 品番
      productNo: null,
      // 品名
      productName: null,
      // 数量
      amount: 0,
      // NET
      net: 0,
      // 最終仕入価格
      lastCost: 0,
      // 営業所名
      salesOfficeName: null,
      // 着日
      inDate: null,
      // 着日未定
      inDateUndecidedFlg: false,
      // 仙台納品数
      sendaiDelivery: 0,
      // 仙南納品数
      sennanDelivery: 0,
      // 仙北納品数
      senbokuDelivery: 0,
      // 備考
      detailsDescription: null,
      // 返伝NO
      returnSlipNo: null,
      // 仙台入荷フラグ
      sendaiInFlg: false,
      // 仙南入荷フラグ
      sennanInFlg: false,
      // 仙北入荷フラグ
      senbokuInFlg: false,
      // 仮伝フラグ
      tempSlipFlg: false,
      // 本伝フラグ
      slipFlg: false,
      // 納品書日付
      deliveryDate: null,
      // 単位
      unit: "個",
      // 定価
      listPrice: null,
      // CSS営業部門コード
      salesOfficeCode: null,
      // JANコード
      productJanCode: null,
      // 入荷時仕入価格
      arrivalPurchasePrice: null,
      // 仕入先伝票番号
      purchaseSlipNo: null,
      // トシン伝票日付
      toshinSplitDate: null,
      // トシン伝票NO
      toshinSplitNo: null,
      // トシン伝票印刷フラグ
      toshinSplitPrintFlg: false,
      // トシン伝票印刷日付
      toshinSplitPrintDate: null,
      // 仕入CSVフラグ
      purchaseCsvFlg: false,
      // 仕入CSV出力日付
      purchaseCsvOutputDate: null,
      // 仕入待ちチェック
      waitingPurchaseFlg: false,
      // キャンセルフラグ
      cancelFlg: false,
      // メール送信済みフラグ
      sentEmailFlg: false,
      // 返品ステータス
      returnStatus: 0,
      // 伝票区分
      splitSection: 1,
      // 引上フラグ
      pullUpFlg: false,
      // 依頼フラグ
      returnRequestFlg: false,
      // 返却フラグ
      returnProductFlg: false,
      // 仕入赤伝フラグ
      purchaseAkaCsvOutputFlg: false,
      // 仕入赤伝日付
      purchaseAkaCsvOutputDate: null,
      // 移動先部門コード
      destinationDepartmentCode: 0,
      // CSS仕入先コード
      cssSupplierCode: null,
      // 仕入先リベート区分
      supplierRebateSection: 0,
      // 着日区分
      inDateSection: 0,
      // 発注書区分
      purchaseOrderSection: 3,

      // テーブルの一意キー
      tableKey: null,
      // データ更新フラグ
      isUpdatedItem: false,
    },

    // 作成日付
    // ●TODO 6か月前の1日
    scOrderDates: [
      new Date(
        Date.now() -
          new Date().getTimezoneOffset() * 60000 -
          60 * 24 * 30 * 6 * 60000
      )
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    scOrderDateMenu: false,

    // 検索条件 着日
    scInDates: [],
    // 検索条件 注文番号開始
    scOrderNoStart: null,
    // 検索条件 注文番号終了
    scOrderNoEnd: null,
    // 検索条件 仕入先コード
    scSupplierCode: null,
    // 検索条件 仕入先伝票番号
    scPurchaseSlipNo: null,
    // 検索条件 営業所名
    scSalesOfficeName: null,
    // 検索条件 CSV出力日付
    scCsvOutPutDates: [],
    // 検索条件 仙南入荷フラグ
    scSennanInFlgRadioGroup: 0,
    // 検索条件 仙台入荷フラグ
    scSendaiInFlgRadioGroup: 0,
    // 検索条件 仙北入荷フラグ
    scSenbokuInFlgRadioGroup: 0,
    // 検索条件 仮伝
    scTempSlipFlgRadioGroup: 0,
    // 検索条件 本伝
    scSlipFlgRadioGroup: 0,
    // 検索条件 キャンセル
    scCancelRadioGroup: 0,

    // アコーディオン
    panel: [1, 0],
    // テーブルフッターオプション
    footerProps: CUSTOM_FOOTER_PROPS_SEARCH_AREA,
    // 商品登録ダイアログ
    dialogProductInsert: false,
    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    numberRequired: (value) =>
      (!!`${value}` && value !== null) || "必ず入力してください",
    // 桁数の制約 最大5桁
    limit_length5: (value) =>
      value == null || value.length <= 5 || "5桁以内で入力してください",
    // 桁数の制約 最大9文字
    limit_length9: (value) =>
      value == null || `${value}`.replace(/,/g, "").trim().length <= 9 || "9桁以内で入力してください",
    // 桁数の制約 最大10文字
    limit_length10: (value) =>
      value == null || value.length <= 10 || "10文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 最大50文字
    limit_length50: (value) =>
      value == null || value.length <= 50 || "50文字以内で入力してください",
    // 文字数の制約 半角40文字、全角20文字
    limit_lengthProductName: value => {
      let len = 0;
      if (value !== null) {
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < value.length; i++) {
          (value[i].match(/[ -~]/)) || (value[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
      }
      return len <= 40 || `半角40文字、全角20文字以内で入力してください`
    },
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]+$/.test(value) || "数値で入力してください",
    // 掛率の制約
    rate: (value) =>
      value == null ||
      /^([1-9][0-9]{0,2}|0)(\.[0-9]{1,5})?$/.test(value) ||
      "小数点以下5桁で入力してください",
    // 価格の制約
    price: (value) =>
      value == null ||
      /^([-+]?[1-9][0-9]{0,7}|0)(\.[0-9]{1,2})?$/.test(
        String(value).replace(/,/g, "").trim()
      ) ||
      "整数値8桁、小数値2桁で入力してください",
    // 数値系のカウント
    numCount: (v) => {
      if (v !== null) {
        return `${v}`.replace(/-/g, "").trim().length;
      } else {
        return 0;
      }
    },
    // 商品項目（sjis）のカウント
    productCount: (v) => {
      if (v !== null) {
        let len = 0;
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < v.length; i++) {
          (v[i].match(/[ -~]/)) || (v[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
        return len;
      } else {
        return 0;
      }
    },
    // チェックボックス
    selected: [],
    // 追加編集ダイアログ
    dialog: false,
    // 削除ダイアログ
    dialogDelete: false,
    // 商品検索ダイアログ
    dialogProductSearch: false,
    reqMailList: [],
    // Datatablesのヘッダー
    headers: [
      { text: "", value: "data-table-select", width: "50px" },
      { text: "操作", value: "actions", sortable: false, width: "42px" },
      { text: "発注日付", value: "orderDate", width: "85px" },
      { text: "注文番号", value: "orderNo", width: "40px" },
      { text: "明細No", value: "orderBranchNo", width: "40px" },
      { text: "品名", value: "productName", width: "220px" },
      { text: "商品コード", value: "productCode", width: "80px" },
      { text: "CSS仕入先コード", value: "cssSupplierCode", width: "80px" },
      { text: "仕入先名", value: "supplierName", width: "100px" },
      { text: "数量", value: "amount", width: "50px" },
      { text: "単位", value: "unit", width: "50px" },
      { text: "キャンセル", value: "cancelFlg", width: "50px"},
      { text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" },
      { text: "NET", value: "net", width: "30px" },
      { text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" },
      { text: "入荷時仕入価格", value: "arrivalPurchasePrice", width: "70px" },
      { text: "最終仕入価格", value: "lastCost", width: "60px" },
      { text: "納品数(仙台)", value: "sendaiDelivery", width: "50px" },
      { text: "納品数(仙南)", value: "sennanDelivery", width: "50px" },
      { text: "納品数(仙北)", value: "senbokuDelivery", width: "50px" },
      { text: "営業部門コード", value: "salesOfficeCode", width: "60px" },
      { text: "営業所名", value: "salesOfficeName", width: "100px" },
      { text: "着日", value: "inDate", width: "85px" },
      { text: "着日未定", value: "inDateUndecidedFlg", width: "40px" },
      { text: "当", value: "inDateToday", width: "40px" },
      { text: "翌", value: "inDateNextday", width: "40px" },
      { text: "同注番Copy", value: "copySameOrderInDate", width: "50px" },
      { text: "備考", value: "detailsDescription", width: "200px" },
      { text: "定価", value: "listPrice", width: "40px" },
      { text: "入荷(仙台)", value: "sendaiInFlg", width: "50px" },
      { text: "入荷(仙南)", value: "sennanInFlg", width: "50px" },
      { text: "入荷(仙北)", value: "senbokuInFlg", width: "50px" },
      { text: "仮伝", value: "tempSlipFlg", width: "40px" },
      { text: "本伝", value: "slipFlg", width: "40px" },
      { text: "トシン経由区分", value: "toshinSection", width: "70px" },
      { text: "トシン伝票日付", value: "toshinSplitDate", width: "80px" },
      { text: "トシン伝票番号", value: "toshinSplitNo", width: "80px" },
      { text: "トシン伝票印刷済", value: "toshinSplitPrintFlg", width: "80px" },
      { text: "トシン伝票印刷日付", value: "toshinSplitPrintDate", width: "100px" },
      { text: "返品", value: "returnFlg", width: "40px" },
      { text: "値引", value: "discountFlg", width: "40px" },
      { text: "良品/不良品", value: "returnStatus", width: "210px" },
      { text: "引上", value: "pullUpFlg", width: "40px" },
      { text: "依頼", value: "returnRequestFlg", width: "40px" },
      { text: "返却", value: "returnProductFlg", width: "40px" },
      { text: "返伝No", value: "returnSlipNo", width: "40px" },
      { text: "仕入赤伝", value: "purchaseAkaCsvOutputFlg", width: "40px" },
      { text: "仕入赤伝日付", value: "purchaseAkaCsvOutputDate", width: "85px" },
      { text: "納品書日付", value: "deliveryDate", width: "85px" },
      { text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" },
    ],
    // テーブルに格納するデータの初期値
    searchItemList: [],
    // 未保存チェック用
    originalParamsDetails: [],
    // 単位のコンボボックスを追加
    unitList: ["個", "組", "箱", "本", "セット", "台", "巻", "m", "面"],
    unit: "個",
    viewUnit: null,
    // ラジオボタン初期設定
    returnStatusList: RETURN_PRODUCT_STATUS,

    // ログインユーザー情報
    loginUserInfo: null,

    // 仕入先リスト（コード、名前）
    supplierList: [],
    // 営業所リスト（コード、名前）
    salesOfficeNameList: [],

    // データテーブル内ダイアログでのカレンダー入力
    inDateSelectMenu: false,
    scInDateMenu: false,
    scCsvOutputDateMenu: false,
    toshinSplitDateMenu: false,
    deliveryDateMenu: false,

    // 検索用テキストボックス無効化フラグ
    isDisableText: false,

    // ダイアログ表示フラグ
    dialogDeliveryDateList: false,
    // ダイアログ内フォーム検証
    isDescriptionValid: true,
    // 納期一覧表ダイアログ内摘要欄
    descriptionForDeliveryDateList: "～ご注文を頂きありがとうございました。又どうぞ宜しくお願い致します～",

    // トシン売上伝票明細データなしダイアログ表示フラグ
    toshinDataNotExistDialog: false,

    // 着日未定チェックボックス
    inDateUndecidedCheckBox: false,  }),
  computed: {
    scOrderDateRange: {
      get() {
        let rangeText = null;
        if (this.scOrderDates.length > 0) {
          if (this.scOrderDates[0] === this.scOrderDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scOrderDates[0];
          } else if (this.scOrderDates[0] > this.scOrderDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scOrderDates[1]} ~ ${this.scOrderDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scOrderDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
    scInDateRange: {
      get() {
        let rangeText = null;
        if (this.scInDates.length > 0) {
          if (this.scInDates[0] === this.scInDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scInDates[0];
          } else if (this.scInDates[0] > this.scInDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scInDates[1]} ~ ${this.scInDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scInDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
    scCsvOutPutRange: {
      get() {
        let rangeText = null;
        if (this.scCsvOutPutDates.length > 0) {
          if (this.scCsvOutPutDates[0] === this.scCsvOutPutDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scCsvOutPutDates[0];
          } else if (this.scCsvOutPutDates[0] > this.scCsvOutPutDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scCsvOutPutDates[1]} ~ ${this.scCsvOutPutDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scCsvOutPutDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
  },
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },

  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    
    // バリデーションエラーをリセット
    this.resetValidate();

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"
    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!this.validateCheck()) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディング画面の開始
        this.$store.commit("setLoading", true);
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // 画面遷移しない
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      this.loginUserInfo.BUMON_CD = 36;

      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.departmentInfo = result.data.getM_SHOKISETTEI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // マスタ系テーブルから値を取得
      if (!(await this.getMasterData())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      
      // 未保存チェック用配列を更新
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 検索ボタン押下処理
     */
    async onClickSearchOrderData() {
      // メソッド名を定義
      const method_name="onClickSearchOrderData"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }

      // バリデーションチェックでエラーの際は検索しない
      if (!this.validateCheckForSearch()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // チェックボックスを解除
      this.selected = [];

      // 画面上の検索条件でWHERE句を作成
      let whereOptions = await this.setWhereOptionsForSearchOrder();
      if (!(await this.getOrderList(whereOptions))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     * 検索条件用のWHERE句生成処理（発注検索）
     */
    async setWhereOptionsForSearchOrder() {
      // メソッド名を定義
      const method_name="setWhereOptionsForSearchOrder"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let whereOptions = "";

      // 検索条件 作成日付
      // 作成日付を昇順ソートする
      this.scOrderDates.sort((a, b) => new Date(a) - new Date(b));
      if (this.scOrderDateRange !== null && this.scOrderDateRange !== "") {
        if (this.scOrderDates.length > 1) {
          whereOptions += ` AND CHUMON_DATE BETWEEN '${this.scOrderDates[0]}' AND '${this.scOrderDates[1]}'`;
        } else {
          whereOptions += ` AND CHUMON_DATE = '${this.scOrderDates[0]}'`;
        }
      }

      // 検索条件 着日
      if(this.inDateUndecidedCheckBox) {
        whereOptions += ` AND CHAKUBI_DATE IS NULL`;
      } else {
        // 着日を昇順ソートする
        this.scInDates.sort((a, b) => new Date(a) - new Date(b));
        if (this.scInDateRange !== null && this.scInDateRange !== "") {
          if (this.scInDates.length > 1) {
            whereOptions += ` AND CHAKUBI_DATE BETWEEN '${this.scInDates[0]}' AND '${this.scInDates[1]}'`;
          } else {
            whereOptions += ` AND CHAKUBI_DATE = '${this.scInDates[0]}'`;
          }
        }
      }

      // 検索条件 注文番号
      if (this.scOrderNoStart !== null && this.scOrderNoStart !== ""
          || this.scOrderNoEnd !== null && this.scOrderNoEnd !== "") {
        if (this.scOrderNoStart !== null && this.scOrderNoEnd !== null) {
          whereOptions += ` AND CHUMON_NO BETWEEN ${this.scOrderNoStart} AND ${this.scOrderNoEnd}`;
        } else if (this.scOrderNoStart !== null) {
          whereOptions += ` AND CHUMON_NO = ${this.scOrderNoStart}`;
        } else {
          whereOptions += ` AND CHUMON_NO = ${this.scOrderNoEnd}`;
        }
      }

      // 検索条件 仕入先コード
      whereOptions +=
        this.scSupplierCode == null || this.scSupplierCode == ""
          ? ""
          : ` AND SHIIRESAKI_CD = ${this.scSupplierCode}`;

      // 検索条件 仕入先伝票番号
      whereOptions +=
        this.scPurchaseSlipNo == null || this.scPurchaseSlipNo == ""
          ? ""
          : ` AND SHIIRE_DENPYO_NO = '${this.scPurchaseSlipNo}'`;

      // 検索条件 営業所名
      whereOptions +=
        this.scSalesOfficeName == null || this.scSalesOfficeName == ""
          ? ""
          : ` AND EIGYOU_NAME LIKE '%${this.scSalesOfficeName}%'`;

      // 検索条件 CSV出力日付
      if (this.scCsvOutPutRange !== null && this.scCsvOutPutRange !== "") {
        if (this.scCsvOutPutDates.length > 1) {
          whereOptions += ` AND SHIIRE_CSV_OUTPUT_DATE BETWEEN '${this.scCsvOutPutDates[0]}' AND '${this.scCsvOutPutDates[1]}'`;
        } else {
          whereOptions += ` AND SHIIRE_CSV_OUTPUT_DATE = '${this.scCsvOutPutDates[0]}'`;
        }
      }

      // 検索条件 仙台入荷
      switch (Number(this.scSendaiInFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND SENDAI_NYUKA_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND SENDAI_NYUKA_FLG <> 1`;
          break;
        default:
          break;
      }

      // 検索条件 仙南入荷
      switch (Number(this.scSennanInFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND SENNAN_NYUKA_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND SENNAN_NYUKA_FLG <> 1`;
          break;
        default:
          break;
      }

      // 検索条件 仙北入荷
      switch (Number(this.scSenbokuInFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND SENBOKU_NYUKA_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND SENBOKU_NYUKA_FLG <> 1`;
          break;
        default:
          break;
      }

      // 検索条件 仮伝
      switch (Number(this.scTempSlipFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND KARIDEN_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND KARIDEN_FLG <> 1`;
          break;
        default:
          break;
      }

      // 検索条件 本伝
      switch (Number(this.scSlipFlgRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND HONDEN_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND HONDEN_FLG <> 1`;
          break;
        default:
          break;
      }

      // 検索条件 キャンセル
      switch (Number(this.scCancelRadioGroup)) {
        case 0: // 全部
          break;
        case 1: // チェック
          whereOptions += ` AND CANCEL_FLG = 1`;
          break;
        case 2: // なし
          whereOptions += ` AND CANCEL_FLG <> 1`;
          break;
        default:
          break;
      }

      // 検索条件(共通) 東北商品センター、発注データID、明細データでソート
      whereOptions += ` AND BUMON_CD = 36 ORDER BY HACCHU_DATA_ID DESC, MEISAI_NO ASC LIMIT ${RESULT_ROWS_COUNT_LIMIT}`;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return whereOptions;
    },
    /**
     * 発注データの取得処理（センター発注明細データ取得VIEW）
     */
    async getOrderList(whereOptions) {
      // メソッド名を定義
      const method_name="getOrderList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 検索条件
      let condition = {
        where_options: whereOptions,
      };
      console.log("condition = " + JSON.stringify(condition));
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI, condition)
        );
        console.log("result "+JSON.stringify(result))
        // 結果判定
        if (result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果を格納
          this.searchItemList = [];
          let resultData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
          console.log("getOrderList：SUCCESS：resultData.length = " + resultData.length);
          if(resultData.length === RESULT_ROWS_COUNT_LIMIT) {
            alert(ALERT_ROWS_COUNT_LIMIT);
          }
          for (const data of resultData) {
            this.searchItemList.push({
              orderDataId: data.HACCHU_DATA_ID,
              departmentCode: data.BUMON_CD,
              panasonicSection: data.PANASONIC_KBN,
              orderNo: data.CHUMON_NO,
              orderDate: data.CHUMON_DATE,
              supplierCode: data.SHIIRESAKI_CD,
              supplierName: data.SHIIRESAKI_NAME,
              commissionSection: data.TESURYO_KBN,
              toshinSection: Boolean(data.TOSHIN_KEIYU_KBN),
              toshinClientNo: data.TOSHIN_OKYAKUSAMA_CD,
              toshinOrderNo: data.TOSHIN_JUHACCHU_NO,
              toshinManagerOrderNo: data.TOSHIN_HACCHUSHA_CHUMON_NO,
              managerCode: data.TANTOSHA_CD,
              managerName: data.TANTOSHA_NAME,
              description: data.TEKIYO,
              orderBranchNo: data.MEISAI_NO,
              productCode: data.SHOHIN_CD,
              productNo: data.HINBAN_NO,
              productName: data.SHOHIN_NAME,
              amount: data.SURYO_SU,
              net: data.NET_GK,
              arrivalPurchasePrice: data.SHIIRE_KAKAKU_GK,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              salesOfficeName: data.EIGYOU_NAME,
              inDate: data.CHAKUBI_DATE,
              inDateUndecidedFlg: data.CHAKUBI_DATE ? false : true,
              purchaseSlipNo: data.SHIIRE_DENPYO_NO,
              detailsDescription: data.BIKO,
              returnSlipNo: data.HENDEN_NO,
              sendaiInFlg: Boolean(data.SENDAI_NYUKA_FLG),
              tempSlipFlg: Boolean(data.KARIDEN_FLG),
              slipFlg: Boolean(data.HONDEN_FLG),
              deliveryDate: data.NOHINSHO_DATE,
              unit: data.TANI_NAME,
              listPrice: data.TEKA_GK,
              salesOfficeCode: data.EIGYOU_BUMON_CD,
              sendaiDelivery: data.SENDAI_NOHINSU_SU,
              sennanDelivery: data.SENNAN_NOHINSU_SU,
              senbokuDelivery: data.SENBOKU_NOHINSU_SU,
              sennanInFlg: Boolean(data.SENNAN_NYUKA_FLG),
              senbokuInFlg: Boolean(data.SENBOKU_NYUKA_FLG),
              productJanCode: data.JAN_CD,
              toshinSplitDate: data.TOSHIN_DENPYO_DATE,
              toshinSplitNo: data.TOSHIN_DENPYO_NO,
              toshinSplitPrintFlg: Boolean(data.TOSHIN_DENPYO_INSATSU_FLG),
              toshinSplitPrintDate: data.TOSHIN_DENPYO_INSATSU_DATE,
              returnStatus: data.HENPIN_STATUS_KBN,
              splitSection: data.DENPYO_KBN,
              returnFlg: data.DENPYO_KBN == 2,
              pullUpFlg: Boolean(data.HIKIAGE_FLG),
              returnRequestFlg: Boolean(data.IRAI_FLG),
              returnProductFlg: Boolean(data.HENKYAKU_FLG),
              discountFlg: data.DENPYO_KBN == 3,
              purchaseAkaCsvOutputFlg: Boolean(data.SHIIRE_AKA_FLG),
              purchaseAkaCsvOutputDate: data.SHIIRE_AKA_DATE,
              purchaseCsvFlg: Boolean(data.SHIIRE_CSV_FLG),
              purchaseCsvOutputDate: data.SHIIRE_CSV_OUTPUT_DATE,
              cssSupplierCode: data.CSS_SHIIRESAKI_CD,
              destinationDepartmentCode: data.IDOSAKI_BUMON_CD,
              cancelFlg: Boolean(data.CANCEL_FLG),
              // 以下、検索画面で使用するデータを格納
              tableKey: String(data.HACCHU_DATA_ID) + String(data.MEISAI_NO),
              isUpdatedItem: false,
              // データテーブル内ダイアログでのカレンダー入力
              inDateSelectMenu: false,
              toshinSplitDateMenu: false,
              deliveryDateMenu: false,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listV_CENTER_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          console.log("getOrderList：error 1");
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listV_CENTER_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getOrderList：error 2 : " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_CENTER_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 取得したデータを加工する。
      for (const data of this.searchItemList) {
        // 伝票区分から返品値引フラグを設定する。
        if (data.splitSection == 2) {
          data.returnFlg = true;
          data.discountFlg = false;
        } else if (data.splitSection == 3) {
          data.returnFlg = false;
          data.discountFlg = true;
        } else {
          data.returnFlg = false;
          data.discountFlg = false;
        }
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 発注データの取得処理（発注明細データ取得VIEW）
     * リストを返却
     */
    async getOrders(whereOptions) {
      // メソッド名を定義
      const method_name="getOrders"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      let condition = {
        where_options: whereOptions,
      };

      let orders = [];
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果を格納
          let resultData = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
          for (const data of resultData) {
            orders.push({
              orderDataId: data.HACCHU_DATA_ID,
              departmentCode: data.BUMON_CD,
              panasonicSection: data.PANASONIC_KBN,
              orderNo: data.CHUMON_NO,
              orderDate: data.CHUMON_DATE,
              supplierCode: data.SHIIRESAKI_CD,
              supplierName: data.SHIIRESAKI_NAME,
              commissionSection: data.TESURYO_KBN,
              toshinSection: Boolean(data.TOSHIN_KEIYU_KBN),
              toshinClientNo: data.TOSHIN_OKYAKUSAMA_CD,
              toshinOrderNo: data.TOSHIN_JUHACCHU_NO,
              toshinManagerOrderNo: data.TOSHIN_HACCHUSHA_CHUMON_NO,
              managerCode: data.TANTOSHA_CD,
              managerName: data.TANTOSHA_NAME,
              description: data.TEKIYO,
              orderBranchNo: data.MEISAI_NO,
              productCode: data.SHOHIN_CD,
              productNo: data.HINBAN_NO,
              productName: data.SHOHIN_NAME,
              amount: data.SURYO_SU,
              net: data.NET_GK,
              arrivalPurchasePrice: data.SHIIRE_KAKAKU_GK,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              salesOfficeName: data.EIGYOU_NAME,
              inDate: data.CHAKUBI_DATE,
              purchaseSlipNo: data.SHIIRE_DENPYO_NO,
              detailsDescription: data.BIKO,
              returnSlipNo: data.HENDEN_NO,
              sendaiInFlg: Boolean(data.SENDAI_NYUKA_FLG),
              tempSlipFlg: Boolean(data.KARIDEN_FLG),
              slipFlg: Boolean(data.HONDEN_FLG),
              deliveryDate: data.NOHINSHO_DATE,
              unit: data.TANI_NAME,
              listPrice: data.TEKA_GK,
              salesOfficeCode: data.EIGYOU_BUMON_CD,
              sendaiDelivery: data.SENDAI_NOHINSU_SU,
              sennanDelivery: data.SENNAN_NOHINSU_SU,
              senbokuDelivery: data.SENBOKU_NOHINSU_SU,
              sennanInFlg: Boolean(data.SENNAN_NYUKA_FLG),
              senbokuInFlg: Boolean(data.SENBOKU_NYUKA_FLG),
              productJanCode: data.JAN_CD,
              toshinSplitDate: data.TOSHIN_DENPYO_DATE,
              toshinSplitNo: data.TOSHIN_DENPYO_NO,
              toshinSplitPrintFlg: Boolean(data.TOSHIN_DENPYO_INSATSU_FLG),
              toshinSplitPrintDate: data.TOSHIN_DENPYO_INSATSU_DATE,
              returnStatus: data.HENPIN_STATUS_KBN,
              splitSection: data.DENPYO_KBN,
              returnFlg: data.DENPYO_KBN == 2,
              pullUpFlg: Boolean(data.HIKIAGE_FLG),
              returnRequestFlg: Boolean(data.IRAI_FLG),
              returnProductFlg: Boolean(data.HENKYAKU_FLG),
              discountFlg: data.DENPYO_KBN == 3,
              purchaseAkaCsvOutputFlg: Boolean(data.SHIIRE_AKA_FLG),
              purchaseAkaCsvOutputDate: data.SHIIRE_AKA_DATE,
              purchaseCsvFlg: Boolean(data.SHIIRE_CSV_FLG),
              purchaseCsvOutputDate: data.SHIIRE_CSV_OUTPUT_DATE,
              cssSupplierCode: data.CSS_SHIIRESAKI_CD,
              destinationDepartmentCode: data.IDOSAKI_BUMON_CD,
              cancelFlg: Boolean(data.CANCEL_FLG),
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listV_CENTER_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listV_CENTER_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_CENTER_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }

      // 取得したデータを加工する。
      for (const data of this.searchItemList) {
        // 伝票区分から返品値引フラグを設定する。
        if (data.splitSection == 2) {
          data.returnFlg = true;
          data.discountFlg = false;
        } else if (data.splitSection == 3) {
          data.returnFlg = false;
          data.discountFlg = true;
        } else {
          data.returnFlg = false;
          data.discountFlg = false;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return orders;
    },
    /**
     * 発注明細Viewデータ更新処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      console.log("updateTable [in]");

      let sqlList = [];
      let toshinManagerOrderNo = null;
      let managerName = null;
      let description = null;
      let supplierName = null;
      let toshinOrderNo = null;
      let managerLoginUserCode = null;
      let userName = null;
      let productName = null;
      let detailsDescription = null;
      let inDate = null;
      let unit = null;
      let salesOfficeName = null
      let deliveryDate = null;
      let toshinSplitPrintDate = null;
      let toshinSplitNo = null;
      let toshinSplitDate = null;
      let purchaseCsvOutputDate = null;
      let purchaseAkaCsvOutputDate = null;
      let purchaseSlipNo = null;
      let productNo = null;
      let productJanCode = null;
      let tempSlipPrintDate = null;
      for (const tableData of this.searchItemList) {
        // 変更があったもののみ更新
        if (!tableData.isUpdatedItem) {
          console.log(
            "tableData.isUpdatedItem = FALSE [" +
              tableData.orderDataId +
              "]"
          );
          continue;
        }
        console.log(
          "tableData.isUpdatedItem = TRUE [" +
            tableData.orderDataId +
            "] " +
            JSON.stringify(tableData)
        );

        toshinManagerOrderNo = !tableData.toshinManagerOrderNo ? null : `'${await this.deleteTabStr(tableData.toshinManagerOrderNo)}'`;
        description = !tableData.description ? null : `'${await this.deleteTabStr(tableData.description)}'`;
        managerLoginUserCode = !tableData.managerLoginUserCode? null: `'${await this.deleteTabStr(tableData.managerLoginUserCode)}'`;
        managerName = !tableData.managerName ? null : `'${await this.deleteTabStr(tableData.managerName)}'`;
        supplierName = !tableData.supplierName ? null : `'${await this.deleteTabStr(tableData.supplierName)}'`;
        toshinOrderNo = !tableData.toshinOrderNo ? null : `'${tableData.toshinOrderNo}'`;
        userName = !this.loginUserInfo.LOGIN_USER_CD ? null : `'${this.loginUserInfo.LOGIN_USER_CD}'`;
        // 発注データ用SQLを追加
        sqlList.push(
          `UPDATE T_CENTER_HACCHU_DATA SET` +
            ` PANASONIC_KBN = ${tableData.panasonicSection},` +
            ` CHUMON_DATE = '${tableData.orderDate}',` +
            ` SHIIRESAKI_CD = ${tableData.supplierCode},` +
            ` SHIIRESAKI_NAME = ${supplierName},` +
            ` TESURYO_KBN = ${tableData.commissionSection},` +
            ` TOSHIN_KEIYU_KBN = ${tableData.toshinSection},` +
            ` TOSHIN_OKYAKUSAMA_CD = ${tableData.toshinClientNo},` +
            ` TOSHIN_JUHACCHU_NO = ${toshinOrderNo},` +
            ` TOSHIN_HACCHUSHA_CHUMON_NO = ${toshinManagerOrderNo},` +
            ` LOGIN_USER_CD = ${managerLoginUserCode},` +
            ` TANTOSHA_CD = ${tableData.managerCode},` +
            ` TANTOSHA_NAME = ${managerName},` +
            ` TEKIYO = ${description},` +
            ` UPDATE_DATE = CURDATE(),` +
            ` UPDATE_USER_NAME = ${userName}` +
            ` WHERE HACCHU_DATA_ID = ${tableData.orderDataId};`
        );

        // 発注明細データ用SQLを追加
        productName = !tableData.productName ? null : `'${await this.deleteTabStr(tableData.productName)}'`;
        detailsDescription = !tableData.detailsDescription ? null : `'${await this.deleteTabStr(tableData.detailsDescription)}'`;
        salesOfficeName = !tableData.salesOfficeName ? null : `'${await this.deleteTabStr(tableData.salesOfficeName)}'`;
        inDate = !tableData.inDate ? null : `'${tableData.inDate}'`;
        deliveryDate = !tableData.deliveryDate ? null : `'${tableData.deliveryDate}'`;
        unit = !tableData.unit ? null : `'${await this.deleteTabStr(tableData.unit)}'`;
        toshinSplitNo = !tableData.toshinSplitNo ? null : `'${await this.deleteTabStr(tableData.toshinSplitNo)}'`;
        tempSlipPrintDate = !tableData.tempSlipPrintDate ? null : `'${tableData.tempSlipPrintDate}'`;
        toshinSplitDate = !tableData.toshinSplitDate ? null : `'${tableData.toshinSplitDate}'`;
        toshinSplitPrintDate = !tableData.toshinSplitPrintDate ? null : `'${tableData.toshinSplitPrintDate}'`;
        purchaseAkaCsvOutputDate = !tableData.purchaseAkaCsvOutputDate ? null : `'${tableData.purchaseAkaCsvOutputDate}'`;
        purchaseCsvOutputDate = !tableData.purchaseCsvOutputDate ? null : `'${tableData.purchaseCsvOutputDate}'`;
        purchaseSlipNo = !tableData.purchaseSlipNo ? null : `'${await this.deleteTabStr(tableData.purchaseSlipNo)}'`;
        productNo = !tableData.productNo ? null : `'${await this.deleteTabStr(tableData.productNo)}'`;
        productJanCode = !tableData.productJanCode ? null : `'${await this.deleteTabStr(tableData.productJanCode)}'`;
        sqlList.push(
          `UPDATE T_CENTER_HACCHU_MEISAI_DATA SET` +
            ` SHOHIN_CD = ${tableData.productCode},` +
            ` HINBAN_NO = ${productNo},` +
            ` SHOHIN_NAME = ${productName},` +
            ` MEISAI_NO = ${tableData.orderBranchNo},` +
            ` SHOHIN_CD = ${tableData.productCode},` +  
            ` SURYO_SU = ${tableData.amount},` +
            ` NET_GK = ${tableData.net},` +
            ` SHIIRE_KAKAKU_GK = ${tableData.arrivalPurchasePrice},` +
            ` SAISHU_SHIIRE_KAKAKU_GK = ${tableData.lastCost},` +
            ` EIGYOU_NAME = ${salesOfficeName},` + 
            ` CHAKUBI_DATE = ${inDate},` +
            ` SHIIRE_DENPYO_NO = ${purchaseSlipNo},` +
            ` BIKO = ${detailsDescription},` +
            ` HENDEN_NO = ${tableData.returnSlipNo},` + 
            ` SENDAI_NYUKA_FLG = ${Number(tableData.sendaiInFlg)},` + 
            ` KARIDEN_FLG = ${Number(tableData.tempSlipFlg)},` + 
            ` HONDEN_FLG = ${Number(tableData.slipFlg)},` +
            ` NOHINSHO_DATE = ${deliveryDate},` +
            ` TANI_NAME = ${unit},` +
            ` TEKA_GK = ${tableData.listPrice},` +
            ` EIGYOU_BUMON_CD = ${tableData.salesOfficeCode},` +
            ` KARIDEN_INSATSU_DATE = ${tempSlipPrintDate},` +
            ` SENDAI_NOHINSU_SU = ${tableData.sendaiDelivery},` +
            ` SENNAN_NOHINSU_SU = ${tableData.sennanDelivery},` +
            ` SENBOKU_NOHINSU_SU = ${tableData.senbokuDelivery},` +
            ` SENNAN_NYUKA_FLG = ${Number(tableData.sennanInFlg)},` + 
            ` SENBOKU_NYUKA_FLG = ${Number(tableData.senbokuInFlg)},` +
            ` JAN_CD = ${productJanCode},` +
            ` TOSHIN_DENPYO_DATE = ${toshinSplitDate},` +
            ` TOSHIN_DENPYO_NO = ${toshinSplitNo},` +
            ` TOSHIN_DENPYO_INSATSU_FLG = ${Number(tableData.toshinSplitPrintFlg)},` +
            ` TOSHIN_DENPYO_INSATSU_DATE = ${toshinSplitPrintDate},` +
            ` HENPIN_STATUS_KBN = ${tableData.returnStatus},` + 
            ` DENPYO_KBN = ${tableData.splitSection},` + 
            ` HIKIAGE_FLG = ${Number(tableData.pullUpFlg)},` +
            ` IRAI_FLG = ${Number(tableData.returnRequestFlg)},` +
            ` HENKYAKU_FLG = ${Number(tableData.returnProductFlg)},` +
            ` SHIIRE_AKA_FLG = ${Number(tableData.purchaseAkaCsvOutputFlg)},` +
            ` SHIIRE_AKA_DATE = ${purchaseAkaCsvOutputDate},` +
            ` SHIIRE_CSV_FLG = ${Number(tableData.purchaseCsvFlg)},` +
            ` SHIIRE_CSV_OUTPUT_DATE = ${purchaseCsvOutputDate},` +
            ` IDOSAKI_BUMON_CD = ${Number(tableData.salesOfficeCode)},` + 
            ` CANCEL_FLG = ${Number(tableData.cancelFlg)},` +
            ` UPDATE_DATE = CURDATE(),` +
            ` UPDATE_USER_NAME = ${userName}`+
            ` WHERE HACCHU_DATA_ID = ${tableData.orderDataId}` +
            ` AND MEISAI_NO = ${tableData.orderBranchNo};`
        );
      }
      console.log(
        "updateTable：before executeTransactSql = " +
          JSON.stringify(sqlList)
      );
      // トランザクション関数の実行
      if (!(await this.executeTransactSql(sqlList))) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.resetValidate();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * tab文字の削除
     */
    async deleteTabStr(csvDataStr) {
      return String(csvDataStr).replace(/\t+/g, " ");
    },
    /**
     * トランザクション関数の実行処理
     */
    async executeTransactSql(sqlList) {
      // メソッド名を定義
      const method_name="executeTransactSql"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let condition = { SQLs: sqlList };
      console.log(
        "executeTransactSql：condition = " +
          JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.log(
              "executeTransactSql：error : " + JSON.stringify(result)
            );
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeTransactSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeTransactSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.log("executeTransactSql : 異常終了 = " + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );

      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
    /**
     * 未保存チェック用比較データの更新(発注明細データVIEW)
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 行ごとの更新フラグをすべてFALSEに戻す
      for (const data of this.searchItemList) {
        data.isUpdatedItem = false;
      }
      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParamsDetails = JSON.parse(JSON.stringify(this.searchItemList));
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let listLength = this.searchItemList.length;
      if (this.originalParamsDetails.length > this.searchItemList.length) {
        listLength = this.originalParamsDetails.length;
      }
      for (var i = 0; i <= listLength; i++) {
        if (
          JSON.stringify(this.originalParamsDetails[i]) !==
          JSON.stringify(this.searchItemList[i])
        ) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return false;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * バリデーションチェック(登録処理時)
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.formDetails;
      if (!form.validate()) {
        // エラー情報を取得
        let result = form.inputs.find((input) => input.hasError);
        console.dir(result);
        for (const header of this.headers) {
          if (header.value == result.id) {
            alert(header.text + "の値が不正です。\n" + result.value);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return false;
          }
        }
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * バリデーションチェック(検索ボタン押下時)
     */
    validateCheckForSearch() {
      // メソッド名を定義
      const method_name="validateCheckForSearch"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      const form = this.$refs.formSearchConditionsOrder;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    // EXCEL出力ボタン
    async outputCenterExcel() {
      // メソッド名を定義
      const method_name="outputCenterExcel"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 未保存のデータがある場合はエラー
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 画面上の検索条件でWHERE句を作成
      let whereOptions = await this.setWhereOptionsForSearchOrder();
      // 発注データ取得
      const orders = await this.getOrders(whereOptions);

      // console.log(orders);

      if(orders.length === RESULT_ROWS_COUNT_LIMIT) {
        alert(ALERT_ROWS_COUNT_LIMIT);
      }

      // Excel出力関数用パラメータ
      const condition = {
        ExcelKind: `center`,
        ExcelData: JSON.stringify(orders),
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      if (orders) {
        try {
          // AppSync→AuroraServerless(MySQL)でデータ取得
          let result = await API.graphql(
            graphqlOperation(executeExcelFileCreation, condition)
          );
          // レスポンスデータを取得できた際の処理
          // console.log({result});

          /**
           * JSON.parseする際に
           * result.data.executeExcelFileCreation.bodyだと
           * dataがundefinedに何故かなるためこのタイミングで代入
           */
          const resultData = result.data;

          if (result) {
            // 関数実行でエラーが発生した場合の処理
            if (result.data.executeExcelFileCreation.statusCode > 200) {
              console.log(
                "executeExcelFileCreation：error : " + JSON.stringify(result)
              );
              // ログ出力(Lamda関数のステータスエラー)
              addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              return false;
            } else {
              // 関数が正常に終了した際の処理
              // console.log("executeExcelFileCreation：SUCCESS ");

              const parsedBody = JSON.parse(resultData.executeExcelFileCreation.body);
              console.log({parsedBody});
              
              // S3からファイルを取得
              const downloadingFileName = parsedBody.fileName.split('/')[2];
              console.log({downloadingFileName});
              const storeGetResult = await Storage.get(`excelOutputs/${downloadingFileName}`, {download: true});
              
              // S3から取得したファイルをダウンロード
              const url = URL.createObjectURL(storeGetResult.Body);
              const a = document.createElement('a');
              a.href = url;
              a.download = downloadingFileName || 'download';
              const clickHandler = () => {
                setTimeout(() => {
                  URL.revokeObjectURL(url);
                  a.removeEventListener('click', clickHandler);
                }, 150);
              };
              a.addEventListener('click', clickHandler, false);
              a.click();

              // ExcelファイルをS3から削除
              await Storage.remove(`excelOutputs/${downloadingFileName}`);

              // ローディング画面の終了
              this.$store.commit("setLoading", false);
              // ログ出力(SQL-end_OK)
              addLog('INFO', {message: MSG.INFO["1004"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
              // ログ出力(method-end)
              addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
              // ファイルを返却
              return a;
            }
          } else {
            // レスポンスデータを取得できなかった際の処理
            console.log("executeExcelFileCreation : responseBody = null：" + JSON.stringify(result));
            // ログ出力(Lamda関数のステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        } catch (error) {
          console.log({error});
          console.log("executeExcelFileCreation : 異常終了 = " + JSON.stringify(error));
          // ログ出力(exception)
          addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeExcelFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
          return false;
        }
      } else {
        //TODO データが無かった場合の処理
      }
      // ローディング画面の終了
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 仕入CSV出力ボタン押下処理
     */
    async onClickPurchaseCsv() {
      // メソッド名を定義
      const method_name="onClickPurchaseCsv"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // データ未保存の場合はCSV出力画面に遷移しない
      if (!this.isSavedData()) {
        alert(ALERT_UNSAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 検索明細に1レコードもない場合
      if (this.searchItemList.length < 1) {
        alert(ALERT_SEARCH_ZERO_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // SQL作成用に条件判定
      let sqlList = [];
      let whereInOption_M_SHIIRESAKI = [];
      let supplierCodeList = [];
      let whereInOption_SHIIRE = [];
      let whereInOption_SHOHIN = [];
      for (const data of this.searchItemList) {
        // 仕入先伝票番号がnull、仕入CSV出力済みフラグがTRUEの場合
        if (data.purchaseSlipNo == null || data.purchaseCsvFlg) {
          // CSV出力対象から外す
          data.removeFlg = true;
          continue;
        }

        // 納品書日付のnullチェック
        if (data.deliveryDate == null) {
          alert(
            `納品書日付が入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 品名のnullチェック
        if (data.productName == null) {
          alert(
            `品名が入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 最終仕入価格のnullチェック
        if (data.lastCost == null) {
          alert(
            `最終仕入価格が入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // NETのnullチェック
        if (data.net == null) {
          alert(
            `NETが入っていない明細があります。\n注文番号      ：${data.orderNo}\n明細No       ：${data.orderBranchNo}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // 仕入先マスタからCSS仕入先コードを取得
        whereInOption_M_SHIIRESAKI.push(data.supplierCode);

        // 商品コードの存在チェック
        whereInOption_SHOHIN.push(data.productCode);
      }

      if (whereInOption_M_SHIIRESAKI.length == 0) {
        for (const data of this.searchItemList) {
          delete data.removeFlg
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_NO_CSV_DATA);
        return;
      }

      // データ取得
      let condition = {
        where_options: `AND SHIIRESAKI_CD IN (${whereInOption_M_SHIIRESAKI.join(
          `, `
        )}) AND BUMON_CD = ${this.loginUserInfo.BUMON_CD}`,
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            supplierCodeList.push({
              code: data.SHIIRESAKI_CD,
              name: data.SHIIRESAKI_NAME,
              cssCode: data.CSS_SHIIRESAKI_CD,
              inDateSection: data.CHAKUBI_KBN,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        alert(ALERT_MESSAGE_COMMON_ERROR);
        console.log(JSON.stringify(error));
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return;
      }

      // CSS仕入先コードのNULLチェック
      for (const listItem of supplierCodeList) {
        if (listItem.cssCode == null) {
          alert(
            `仕入先マスタにCSS仕入先コードが設定されていません。\n仕入先コード：${listItem.code}\n仕入先名：${listItem.name}`
          );
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // CSS仕入先コード
        whereInOption_SHIIRE.push(listItem.cssCode);
      }

      // 仕入先基本情報テーブルから仕入先名を取得
      if (whereInOption_SHIIRE.length !== 0) {
        sqlList.push(
          `SELECT SI_TORCD, SI_TORNM1, SI_SEICD FROM SHIIRE WHERE SI_TORCD IN (${whereInOption_SHIIRE.join(
            `, `
          )})`
        );
      }
      if (whereInOption_SHOHIN.length !== 0) {
        sqlList.push(
          `SELECT SH_SHOCD FROM SHOHIN WHERE SH_SHOCD IN (${whereInOption_SHOHIN.join(
            `, `
          )})`
        );
      }

      let resultSHIIRE = [];
      let resultSHOHIN = [];

      // SQLを実行する
      let result = await this.executeHanbaikanriSql(sqlList);
      if (!result) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      for (let i = 0; i < result.length; i++) {
        switch (i) {
          case 0: // SELECT … FROM SHIIRE
            resultSHIIRE = result[i];
            // 請求先コードのnullチェック
            for (const requestData of whereInOption_SHIIRE) {
              let data = resultSHIIRE.find(
                (items) => items.SI_TORCD == requestData
              );
              if (!data) {
                alert(
                  `仕入先基本情報テーブルに請求先コードが設定されていません。\nCSS仕入先コード：${requestData}`
                );
                // ローディングを解除
                this.$store.commit("setLoading", false);
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return;
              }
            }
            break;
          case 1: // SELECT … FROM SHOHIN
            resultSHOHIN = result[i];
            // 商品コードのnullチェック
            for (const requestData of whereInOption_SHOHIN) {
              let data = resultSHOHIN.find(
                (items) => items.SH_SHOCD == requestData
              );
              if (!data) {
                alert(
                  `商品テーブルに商品が登録されていません。\n商品コード：${requestData}`
                );
                // ローディングを解除
                this.$store.commit("setLoading", false);
                // ログ出力(method-end)
                addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
                return;
              }
            }
            break;
          default:
            break;
        }
      }

      // 仕入CSVデータテーブルのデータ削除
      sqlList = [];
      sqlList.push(
        `DELETE FROM W_CENTER_SHIIRE_CSV_DATA WHERE BUMON_CD = ${this.loginUserInfo.BUMON_CD} AND TANTOSHA_CD = ${this.loginUserInfo.TANTOSHA_CD};`
      );

      // 仕入CSVデータ挿入用のデータを生成
      let targetSuppier = null;
      let purchaseUnitPrice = null;
      let cssSupplierName = null;
      let cssSupplierCode = null;
      let purchaseSlipNo = null;
      let productName = null;
      for (const data of this.searchItemList) {
        // CSV出力対象から削除するデータは以降の処理を行わない
        if (data.removeFlg) {
          delete data.removeFlg;
          continue;
        }

        // CSS仕入先コード・CSS仕入先名をセット
        targetSuppier = supplierCodeList.find(
          (items) => items.code === data.supplierCode
        );
        cssSupplierCode = targetSuppier.cssCode;
        cssSupplierName = resultSHIIRE.find(
          (items) => items.SI_TORCD === cssSupplierCode
        ).SI_TORNM1;

        // 仕入単価をセット
        // トシン経由区分が1の場合、最終仕入価格、それ以外はNET
        purchaseUnitPrice = data.toshinSection ? data.lastCost : data.net;

        // カンマ区切りを外す
        purchaseUnitPrice = String(purchaseUnitPrice).replace(/,/g, "").trim();
        purchaseUnitPrice = Number(purchaseUnitPrice);
        productName = !data.productName ? null : `'${await this.deleteTabStr(data.productName)}'`;
        purchaseSlipNo = !data.purchaseSlipNo ? null : `'${await this.deleteTabStr(data.purchaseSlipNo)}'`;
        sqlList.push(
          `INSERT INTO W_CENTER_SHIIRE_CSV_DATA (BUMON_CD, TANTOSHA_CD, HACCHU_DATA_ID, MEISAI_NO, SHIIRE_DENPYO_NO, CHUMON_NO, SHOHIN_CD, HINBAN_NO, SHIIRESAKI_CD, CHAKUBI_DATE, JITSU_DATE, CSS_SHIIRESAKI_NAME, SURYO_SU, SHIIRE_TANKA_GK, CSS_SHIIRESAKI_CD, IDOSAKI_BUMON_CD, DENPYO_KBN, CREATE_USER_NAME, CREATE_DATE, UPDATE_USER_NAME, UPDATE_DATE) VALUES (` +
            `${this.loginUserInfo.BUMON_CD},` + // BUMON_CD
            `${this.loginUserInfo.TANTOSHA_CD},` + // TANTOSHA_CD
            `${data.orderDataId},` + // HACCHU_DATA_ID
            `${data.orderBranchNo},` + // MEISAI_NO
            `${purchaseSlipNo},` + // SHIIRESAKI_DENPYO_NO
            `${data.orderNo},` + // CHUMON_NO
            `${data.productCode},` + // SHOHIN_CD
            `${productName},` + // HINBAN_NO
            `${data.supplierCode},` + // SHIIRESAKI_CD
            `'${data.deliveryDate}',` + // CHAKUBI_DATE
            `'${data.deliveryDate}',` + // JITSU_DATE
            `'${cssSupplierName}',` + // CSS_SHIIRESAKI_NAME
            `${data.amount},` + // SURYO_SU
            `${purchaseUnitPrice},` + // SHIIRE_TANKA_GK
            `${cssSupplierCode},` + // CSS_SHIIRESAKI_CD
            `${data.salesOfficeCode},` + // IDOSAKI_BUMON_CD
            `${data.splitSection},` + // DENPYO_KBN
            `'${this.loginUserInfo.LOGIN_USER_CD}',` + //CREATE_USER_NAME
            `CURDATE(),` + //CREATE_DATE
            `'${this.loginUserInfo.LOGIN_USER_CD}',` + //UPDATE_USER_NAME
            `CURDATE());` //UPDATE_DATE
        );
      }
      console.log(
        "onClickPurchaseCsv：before executeTransactSql = " +
          JSON.stringify(sqlList)
      );

      // トランザクション関数の実行
      if (sqlList.length > 1) {
        if (!(await this.executeTransactSql(sqlList))) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // CSV出力画面に遷移
        this.$router.push("/center_csv_print");
      } else {
        for (const data of this.searchItemList) {
          delete data.removeFlg
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        alert(ALERT_NO_CSV_DATA);
        return;
      }
      
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    // トシン伝票明細出力ボタン
    async outputToshinSalesSlip() {
      // メソッド名を定義
      const method_name="outputToshinSalesSlip"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      
      // 未保存チェックを実施し、未保存の場合はエラーを表示
      if (!this.isSavedData()) {
        if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
          // バリデーションチェックでエラーの際は登録しない
          if (!this.validateCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }

          // ローディング画面の開始
          this.$store.commit("setLoading", true);
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // 完了ダイアログを表示
          alert(ALERT_COMPLETE_CRUD_DATA);
        }
      }
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 出力対象のレコードが存在するかチェック
      // 画面上の検索条件でWHERE句を作成
      let whereOptionsForSearchOrder = await this.setWhereOptionsForSearchOrder();

      // ORDER BY句で切り出し
      let whereOptionsSplit = whereOptionsForSearchOrder.split(`ORDER BY`);
      // console.log({whereOptionsSplit});

      let whereOptions = whereOptionsSplit[0];
      whereOptions += "AND TOSHIN_KEIYU_KBN = 1";
      whereOptions += " AND TOSHIN_DENPYO_DATE IS NOT NULL ";
      whereOptions += " AND TOSHIN_DENPYO_INSATSU_FLG = 0 ";
      whereOptions += " AND TOSHIN_DENPYO_INSATSU_DATE IS NULL";

      // console.log({whereOptions});

      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_CENTER_HACCHU_DATA_WITH_MEISAI, {where_options: whereOptions})
        );
        // 結果判定
        if (result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI) {
          const items = result.data.listV_CENTER_HACCHU_DATA_WITH_MEISAI;
          if (items.length > 0) {
            // データを取得できた場合
            // トシン伝票明細PDF出力処理

            // パラメータをBase64エンコード
            const options = {
              isCenter: true,
              whereOptions: whereOptions,
            };
            const encoded = await base64Encode(JSON.stringify(options));

            let route = this.$router.resolve({ name: 'CenterToshinSalesSlipDetails', params: { params: encoded }});
            window.open(route.href, '_blank');
          } else {
            // データがない場合
            // console.log('toshin data not exists');
            this.toshinDataNotExistDialog = true;
          }
          // ローディングを解除
          this.$store.commit("setLoading", false);
        } else {
          // データを取得できなかった場合
          //TODO データ取得無しのモーダル表示
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        return false;
      }
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      console.log("onClickRegister [in]");
      // バリデーションチェックでエラーの際は登録しない
      if (!(await this.validateCheck())) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      if (this.searchItemList.length === 0) {
        alert("検索結果に1件もレコードがありません。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      if (!confirm(CONFIRM_MESSAGE_SAVE_DATA)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 修正：入力データで更新
      if (!(await this.updateTable())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // 完了ダイアログを表示
      alert(ALERT_COMPLETE_CRUD_DATA);

      // バリデーションエラーをリセット
      this.resetValidate();

      console.log("onClickRegister [out]");
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 明細データ用編集カードのOK処理
     */
    save(item) {
      // メソッド名を定義
      const method_name="save"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);

      // 着日未定フラグON/OFF
      item.inDateUndecidedFlg = item.inDate ? false : true;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  データテーブルの単位編集ダイアログ open
     */
    openUnit(item) {
      // メソッド名を定義
      const method_name="openUnit"
      // オペレーションタイプを定義
      const operation_type="0004"
      this.viewUnit = item.unit;
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     *  データテーブルの単位編集ダイアログ OKボタン
     */
    saveUnit(item) {
      // メソッド名を定義
      const method_name="saveUnit"
      // オペレーションタイプを定義
      const operation_type="0004"
      this.$refs.unitComboBox.blur();
      this.$nextTick(() => {
        item.unit = this.viewUnit;
        // 更新フラグを立てる
        this.changeIsUpdatedItem(item);
      });
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    },
    /**
     * 明細項目変更時のisUpdatedItem更新処理
     */
    changeIsUpdatedItem(item) {
      // メソッド名を定義
      const method_name="changeIsUpdatedItem"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let editedIndex = this.searchItemList.indexOf(item);
      let result = this.originalParamsDetails[editedIndex];
      // isUpdatedItemを削除したリストを生成
      let newResult =  Object.assign({}, result);
      let newItem = Object.assign({}, item);
      delete newResult.isUpdatedItem;
      delete newItem.isUpdatedItem; 

      // 初期状態から変更があれば、更新フラグを立てる
      item.isUpdatedItem = JSON.stringify(newResult) !== JSON.stringify(newItem);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    cancel() {
      // メソッド名を定義
      const method_name="cancel"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 編集ボタン押下時処理
     */
    editItem(item) {
      // メソッド名を定義
      const method_name="editItem"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 入力画面に編集対象の発注データIDを渡す
      this.$router.push({
        name: "CenterOrderInput",
        query: {
          reqOrderDataId: item.orderDataId,
        },
      });

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * マスタ系テーブルからデータを取得
     * ・商品テーブル
     * ・営業所テーブル
     */
    async getMasterData() {
      //  販売管理システムの商品基本テーブルから商品コードを取得。
      // メソッド名を定義
      const method_name="getMasterData"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 仕入先カナ用コンボボックスのリスト生成
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " ORDER BY SHIIRESAKI_CD",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.listM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listM_SHIIRESAKI;
          for (const data of resultData) {
            this.supplierList.push({
              comboItem: data.SHIIRESAKI_CD + "：" + data.SHIIRESAKI_KANA_NAME,
              code: data.SHIIRESAKI_CD,
              name: data.SHIIRESAKI_NAME,
              toshinSection: data.TOSHIN_KEIYU_KBN,
              panasonicSection: data.PANASONIC_KBN,
              commissionSection: data.TESURYO_KBN,
              rebateSection: data.SHIIRESAKI_REBATE_KBN,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query:"listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      //  販売管理システムの営業所テーブルから営業所情報を取得。
      let sqlClassificationList = [
        "SELECT EIGYOU.BU_EBUCD, EIGYOU.BU_EBUNM, EIGYOU.BU_KANA FROM EIGYOU INNER JOIN KANRI ON EIGYOU.BU_EBUCD = KANRI.SY_BASCD ORDER BY EIGYOU.BU_EBUCD",
      ];
      console.log("sqlClassificationList " + sqlClassificationList)
      let resultSalesOfficeData = await this.executeHanbaikanriSql(sqlClassificationList);
      if (!resultSalesOfficeData || !resultSalesOfficeData[0][0]) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      for (const data of resultSalesOfficeData[0]) {
        this.salesOfficeNameList.push({
          comboItem: data.BU_EBUCD + "：" + data.BU_EBUNM,
          code: data.BU_EBUCD,
          name: data.BU_EBUNM,
        });
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },

    /**
     * 営業所名選択時の自動入力
     */
    async changeSalesOfficeNameList(item) {
      // メソッド名を定義
      const method_name="changeSalesOfficeNameList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 営業所名未選択の場合はreturn
      if (!item.salesOfficeName) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 選択した営業所名のデータを取得
      const searchClient = this.salesOfficeNameList.find(
        (items) => items.name === item.salesOfficeName
      );
      // リストに存在しない場合、何もしない
      if (!searchClient) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      item.salesOfficeCode = searchClient.code;
      item.salesOfficeName = searchClient.name;

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     * 当日ボタン押下時の自動入力
     */
    async setDateToday(item, id) {
      // メソッド名を定義
      const method_name="setDateToday"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 当日日付を取得
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, "0") +
        String(date.getDate()).padStart(2, "0");
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 営業日の取得
      // 検索条件
      let resultclassificationData;
      this.todayDate = new Date();
      let sqlSerchList = [
        "select * from OK_V_EIGYOUDATE where ED_DATE=" + sysdate,
      ];

      resultclassificationData = await this.executeHanbaikanriSql(sqlSerchList);
      if (!resultclassificationData || !resultclassificationData[0][0]) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 値を設定する
      let returnDate =
        String(resultclassificationData[0][0].ED_DATE).substr(0, 4) +
        "-" +
        String(resultclassificationData[0][0].ED_DATE).substr(4, 2) +
        "-" +
        String(resultclassificationData[0][0].ED_DATE).substr(6, 2);
      if (id === 0) {
        item.inDate = returnDate;
        item.inDateUndecidedFlg = false;
      } else {
        item.tempSlipPrintDate = returnDate;
      }

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 翌日ボタン押下時の自動入力
     */
    async setDateNextday(item, id) {
      // メソッド名を定義
      const method_name="setDateNextday"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 当日日付を取得
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, "0") +
        String(date.getDate()).padStart(2, "0");
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 営業日の取得
      // 検索条件
      let resultclassificationData;
      this.todayDate = new Date();
      let sqlSerchList = [
        "select * from OK_V_EIGYOUDATE where ED_DATE=" + sysdate,
      ];

      resultclassificationData = await this.executeHanbaikanriSql(sqlSerchList);
      if (!resultclassificationData || !resultclassificationData[0][0]) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      let returnDate =
        String(resultclassificationData[0][0].NEXT_DATE).substr(0, 4) +
        "-" +
        String(resultclassificationData[0][0].NEXT_DATE).substr(4, 2) +
        "-" +
        String(resultclassificationData[0][0].NEXT_DATE).substr(6, 2);
      // 値を設定する
      if (id === 0) {
        item.inDate = returnDate;
        item.inDateUndecidedFlg = false;
      } else {
        item.tempSlipPrintDate = returnDate;
      }

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 同注番Copyボタン押下時の自動入力
     */
     async copyInDateToSameOrder(item) {
      // メソッド名を定義
      const method_name="copyInDateToSameOrder"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      const inDate = item.inDate;
      const orderNo = item.orderNo;

      for (const searchItem of this.searchItemList) {
        if (searchItem.orderNo !== orderNo) continue;
        searchItem.inDate = inDate;
        searchItem.inDateUndecidedFlg = searchItem.inDate ? false : true
        searchItem.isUpdatedItem = true;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  トシン伝票番号更新時の処理
     */
    toshinSplitNo(item) {
      // メソッド名を定義
      const method_name="toshinSplitNo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // トシン伝票番号が0またはNULLの場合
      if (!item.toshinSplitNo || item.toshinSplitNo == 0) {
        item.inFlg = false;
        item.tempSlipFlg = false;
        // それ以外の場合
      } else {
        item.inFlg = true;
        item.tempSlipFlg = true;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  納品書日付更新時の処理
     */
    deliveryDate(item) {
      // メソッド名を定義
      const method_name="deliveryDate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // トシン伝票日付が0またはNULLの場合
      if (!item.deliveryDate) {
        item.tempSlipFlg = false;
        item.slipFlg = false;
        // それ以外の場合
      } else {
        item.tempSlipFlg = true;
        item.slipFlg = true;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  トシン伝票日付更新時の処理
     */
    toshinSplitDate(item) {
      // メソッド名を定義
      const method_name="toshinSplitDate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // トシン伝票日付が0またはNULLの場合
      if (!item.toshinSplitDate || item.toshinSplitDate == 0) {
        item.tempSlipFlg = false;
        // それ以外の場合
      } else {
        item.tempSlipFlg = true;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  仕入先伝票番号更新時の処理
     */
    purchaseSlipNo(item) {
      // メソッド名を定義
      const method_name="purchaseSlipNo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      if (item.toshinSection === true) {
        // 仕入先伝票番号が空欄またはNULLの場合
        if (item.purchaseSlipNo == "" || item.purchaseSlipNo == null) {
          item.slipFlg = false;
          // それ以外の場合
        } else {
          item.slipFlg = true;
        }
      } else {
        // 仕入先伝票番号が空欄またはNULLの場合
        if (item.purchaseSlipNo == "" || item.purchaseSlipNo == null) {
          item.tempSlipFlg = false;
          item.slipFlg = false;
          // それ以外の場合
        } else {
          item.tempSlipFlg = true;
          item.slipFlg = true;
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     *  商品修正からの返り値を元に、マスタ情報を検索する
     */
    response(item) {
      // メソッド名を定義
      const method_name="response"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (item) {
        let returnList = this.searchItemList.find(
          (items) =>
            Number(String(items.orderDataId) + String(items.orderBranchNo)) ===
            item.returnNo
        );
        returnList.productCode = item.returnProductCode;
        this.getProductList(returnList);
        // 初期状態と変更があれば更新フラグを立てる
        this.changeIsUpdatedItem(item);
      }
      // 各変数を初期化する
      this.dialogProductSearch = false;
      this.dialogProductInsert = false;
      this.editProductCode = 0;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 商品登録画面からの返り値の取得
     */
    async getProductList(item) {
      // メソッド名を定義
      const method_name="getProductList"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 商品コードに紐づくデータを取得
      let resultSearchData;
      let sqlSerchList = [
        "SELECT * FROM SHOHIN  WHERE SH_SHOCD =" + item.productCode,
      ];
      resultSearchData = await this.executeHanbaikanriSql(sqlSerchList);
      // エラーが発生した場合
      if (!resultSearchData || !resultSearchData[0][0]) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 結果判定
      if (item.orderBranchNo !== "") {
        item.productNo = resultSearchData[0][0].SH_SHOKNM;
        item.productName = resultSearchData[0][0].SH_SHONM;
        item.productCode = resultSearchData[0][0].SH_SHOCD;
        item.arrivalPurchasePrice = resultSearchData[0][0].SH_SIRTNA;
        item.productJanCode = resultSearchData[0][0].SH_JAN;
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 明細行を選択した内容で絞り込む
     */
    updateSummaryCombo() {
      // メソッド名を定義
      const method_name="updateSummaryCombo"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      this.headers = [];
      // 共通出力項目
      this.headers.push({ text: "", value: "data-table-select", width: "50px" });
      this.headers.push({ text: "操作", value: "actions", sortable: false, width: "42px" });
      this.headers.push({ text: "発注日付", value: "orderDate", width: "85px" });
      this.headers.push({ text: "注文番号", value: "orderNo", width: "40px" });
      this.headers.push({ text: "明細No", value: "orderBranchNo", width: "40px" });
      this.headers.push({ text: "品名", value: "productName", width: "220px" });
      this.headers.push({ text: "商品コード", value: "productCode", width: "80px" });
      this.headers.push({ text: "CSS仕入先コード", value: "cssSupplierCode", width: "80px" });
      this.headers.push({ text: "仕入先名", value: "supplierName", width: "100px" });
      this.headers.push({ text: "数量", value: "amount", width: "50px" });
      this.headers.push({ text: "単位", value: "unit", width: "50px" });
      this.headers.push({ text: "キャンセル", value: "cancelFlg", width: "50px"});
      switch (this.filterValue) {
        case 0: // 全部
          this.headers.push({ text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" });
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" });
          this.headers.push({ text: "入荷時仕入価格", value: "arrivalPurchasePrice", width: "70px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "納品数(仙台)", value: "sendaiDelivery", width: "50px" });
          this.headers.push({ text: "納品数(仙南)", value: "sennanDelivery", width: "50px" });
          this.headers.push({ text: "納品数(仙北)", value: "senbokuDelivery", width: "50px" });
          this.headers.push({ text: "営業部門コード", value: "salesOfficeCode", width: "60px" });
          this.headers.push({ text: "営業所名", value: "salesOfficeName", width: "100px" });
          this.headers.push({ text: "着日", value: "inDate", width: "85px" });
          this.headers.push({ text: "着日未定", value: "inDateUndecidedFlg", width: "40px" });
          this.headers.push({ text: "当", value: "inDateToday", width: "40px" });
          this.headers.push({ text: "翌", value: "inDateNextday", width: "40px" });
          this.headers.push({ text: "同注番コピー", value: "copySameOrderInDate", width: "50px" });
          this.headers.push({ text: "備考", value: "detailsDescription", width: "200px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          this.headers.push({ text: "入荷(仙台)", value: "sendaiInFlg", width: "50px" });
          this.headers.push({ text: "入荷(仙南)", value: "sennanInFlg", width: "50px" });
          this.headers.push({ text: "入荷(仙北)", value: "senbokuInFlg", width: "50px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          this.headers.push({ text: "トシン経由区分", value: "toshinSection", width: "70px" });
          this.headers.push({ text: "トシン伝票日付", value: "toshinSplitDate", width: "80px" });
          this.headers.push({ text: "トシン伝票番号", value: "toshinSplitNo", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷済", value: "toshinSplitPrintFlg", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷日付", value: "toshinSplitPrintDate", width: "100px" });
          this.headers.push({ text: "返品", value: "returnFlg", width: "40px" });
          this.headers.push({ text: "値引", value: "discountFlg", width: "40px" });
          this.headers.push({ text: "良品/不良品", value: "returnStatus", width: "210px" });
          this.headers.push({ text: "引上", value: "pullUpFlg", width: "40px" });
          this.headers.push({ text: "依頼", value: "returnRequestFlg", width: "40px" });
          this.headers.push({ text: "返却", value: "returnProductFlg", width: "40px" });
          this.headers.push({ text: "返伝No", value: "returnSlipNo", width: "40px" });
          this.headers.push({ text: "仕入赤伝", value: "purchaseAkaCsvOutputFlg", width: "40px" });
          this.headers.push({ text: "仕入赤伝日付", value: "purchaseAkaCsvOutputDate", width: "85px" });
          this.headers.push({ text: "納品書日付", value: "deliveryDate", width: "100px" });
          this.headers.push({ text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" });
          break;
        case 1: // 数量
          this.headers.push({ text: "納品数(仙台)", value: "sendaiDelivery", width: "50px" });
          this.headers.push({ text: "納品数(仙南)", value: "sennanDelivery", width: "50px" });
          this.headers.push({ text: "納品数(仙北)", value: "senbokuDelivery", width: "50px" });
          break;
        case 2: // 納期
          this.headers.push({ text: "着日", value: "inDate", width: "85px" });
          this.headers.push({ text: "着日未定", value: "inDateUndecidedFlg", width: "40px" });
          this.headers.push({ text: "当", value: "inDateToday", width: "40px" });
          this.headers.push({ text: "翌", value: "inDateNextday", width: "40px" });
          this.headers.push({ text: "同注番コピー", value: "copySameOrderInDate", width: "50px" });
          this.headers.push({ text: "備考", value: "detailsDescription", width: "200px" });
          break;
        case 3: // 金額
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "定価", value: "listPrice", width: "40px" });
          break;
        case 4: // 入荷
          this.headers.push({ text: "営業所名", value: "salesOfficeName", width: "100px" });
          this.headers.push({ text: "入荷(仙台)", value: "sendaiInFlg", width: "50px" });
          this.headers.push({ text: "入荷(仙南)", value: "sennanInFlg", width: "50px" });
          this.headers.push({ text: "入荷(仙北)", value: "senbokuInFlg", width: "50px" });
          this.headers.push({ text: "仮伝", value: "tempSlipFlg", width: "40px" });
          this.headers.push({ text: "本伝", value: "slipFlg", width: "40px" });
          break;
        case 5: // 仕入
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" });
          this.headers.push({ text: "納品書日付", value: "deliveryDate", width: "100px" });
          this.headers.push({ text: "仕入CSV", value: "purchaseCsvFlg", width: "40px" });
          this.headers.push({ text: "仕入CSV作成日付", value: "purchaseCsvOutputDate", width: "85px" });
          break;
        case 6: // トシン
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "トシン経由区分", value: "toshinSection", width: "70px" });
          this.headers.push({ text: "トシン伝票日付", value: "toshinSplitDate", width: "80px" });
          this.headers.push({ text: "トシン伝票番号", value: "toshinSplitNo", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷済", value: "toshinSplitPrintFlg", width: "80px" });
          this.headers.push({ text: "トシン伝票印刷日付", value: "toshinSplitPrintDate", width: "100px" });
          break;
        case 7: // 返品
          this.headers.push({ text: "良品/不良品", value: "returnStatus", width: "210px" });
          this.headers.push({ text: "引上", value: "pullUpFlg", width: "40px" });
          this.headers.push({ text: "依頼", value: "returnRequestFlg", width: "40px" });
          this.headers.push({ text: "返却", value: "returnProductFlg", width: "40px" });
          this.headers.push({ text: "仕入赤伝", value: "purchaseAkaCsvOutputFlg", width: "40px" });
          this.headers.push({ text: "仕入赤伝日付", value: "purchaseAkaCsvOutputDate", width: "85px" });
          break;
        case 8: // 通常
          // 通常のみ共通のヘッダーではないためクリア
          this.headers = [];
          this.headers.push({ text: "", value: "data-table-select", width: "50px" });
          this.headers.push({ text: "操作", value: "actions", sortable: false, width: "42px" });
          this.headers.push({ text: "発注日付", value: "orderDate", width: "85px" });
          this.headers.push({ text: "注文番号", value: "orderNo", width: "40px" });
          this.headers.push({ text: "明細No", value: "orderBranchNo", width: "40px" });
          this.headers.push({ text: "品名", value: "productName", width: "220px" });
          this.headers.push({ text: "仕入先名", value: "supplierName", width: "100px" });
          this.headers.push({ text: "数量", value: "amount", width: "50px" });
          this.headers.push({ text: "NET", value: "net", width: "30px" });
          this.headers.push({ text: "最終仕入価格", value: "lastCost", width: "60px" });
          this.headers.push({ text: "営業所名", value: "salesOfficeName", width: "100px" });
          this.headers.push({ text: "着日", value: "inDate", width: "85px" });
          this.headers.push({ text: "着日未定", value: "inDateUndecidedFlg", width: "40px" });
          break;
        default:
          break;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  スタイル変更処理
     */
    rowClasses(item) {
      // メソッド名を定義
      const method_name="rowClasses"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (item.returnFlg == true) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return "style-1";
      } else if (item.discountFlg == true) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return "style-2";
      } else if (item.cancelFlg == true) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return "style-3";
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * NETの値が変更された時の処理
     */
    async calcNet(item) {
      // メソッド名を定義
      const method_name="calcNet"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 仕入先コードに紐づくリベート区分を取得
      // コンボボックス用リストの取得
      // 検索条件
      let condition = {
        BUMON_CD : this.loginUserInfo.BUMON_CD,
        SHIIRESAKI_CD: item.supplierCode
      };
      let resultData;
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHIIRESAKI, condition)
        );
        // 結果判定
        if (result.data.getM_SHIIRESAKI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          resultData = result.data.getM_SHIIRESAKI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query:"getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
        } else {
          if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log("異常系ログ:" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHIIRESAKI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // リベートフラグをfalseにする
      // 最終仕入価格計算条件
      switch (resultData.SHIIRESAKI_REBATE_KBN) {
        case 1: // リベート区分が1の場合
          item.lastCost = parseFloat(item.net) * 0.94 * 1.0278;
          break;
        case 2: // リベート区分が2の場合
          item.lastCost = parseFloat(item.net) * 0.97 * 1.0278;
          break;
        default: // リベート区分が上記以外の場合
          // 手数料区分が1の場合はNET * 1.0278を、それでなければNETの値を最終仕入価格に格納
          item.lastCost = resultData.TESURYO_KBN !== 1 ? item.net : parseFloat(item.net) * 1.0278;
          break;
      }
      if (String(item.lastCost).indexOf(".") != -1) {
        item.lastCost = item.lastCost.toFixed(2);
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * バリデーションチェックをリセット
     */
    resetValidate() {
      // メソッド名を定義
      const method_name="resetValidate"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ヘッダーのバリデーションをリセット
      this.$refs.formSearchConditionsOrder.resetValidation();
      // 明細行のバリデーションをリセット
      if (this.$refs.formDetails) {
        this.$refs.formDetails.resetValidation();
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 数量の自動入力処理
     */
    totalAmount(item) {
      // メソッド名を定義
      const method_name="totalAmount"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      item.amount = item.sendaiDelivery + item.sennanDelivery + item.senbokuDelivery;

      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  トシン印刷済みフラグ更新時の処理
     */
    changeToshinSplitPrintFlg(item) {
      // メソッド名を定義
      const method_name="changeToshinSplitPrintFlg"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // チェックを外した場合
      if (!item.toshinSplitPrintFlg) {
        item.toshinSplitPrintDate = null;
      }
      // 初期状態と変更があれば更新フラグを立てる
      this.changeIsUpdatedItem(item);
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     *  商品修正を開く処理
     */
    async callEditProduct(item) {
      // メソッド名を定義
      const method_name="callEditProduct"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let sqlClassificationList = [
        `SELECT SH_SHOCD, SH_SBUBCD FROM SHOHIN WHERE SH_SHOCD = ${item.productCode}`,
      ];
      let resultProductData = await this.executeHanbaikanriSql(sqlClassificationList);
      // エラーが発生した場合
      if (!resultProductData || !resultProductData[0][0]) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // サン電子商品（商品分類コードが10000 ~ 19999まで）は編集禁止
      if (resultProductData[0][0].SH_SBUBCD >= 10000 && resultProductData[0][0].SH_SBUBCD <= 19999) {
        alert(ALERT_SUNELE_EDIT);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      this.dialogProductInsert = !this.dialogProductInsert,
      // targetBranchNoに一意キー(発注データID + 明細No)を代入
      this.targetBranchNo = Number(String(item.orderDataId) + String(item.orderBranchNo))
      this.editProductCode = item.productCode
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
  },
};
</script>
<style scoped>
/* 1列目スクロール時固定 */
.v-data-table >>> th:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(1) {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
} 
/* 2列目スクロール時固定 */
.v-data-table >>> th:nth-child(2) {
  position: sticky;
  left: 50px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(2) {
  position: sticky;
  left: 50px;
  z-index: 1;
  background-color: white;
}
/* 3列目スクロール時固定 */
.v-data-table >>> th:nth-child(3) {
  position: sticky;
  left: 92px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(3) {
  position: sticky;
  left: 92px;
  z-index: 1;
  background-color: white;
}
/* 4列目スクロール時固定 */
.v-data-table >>> th:nth-child(4) {
  position: sticky;
  left: 177px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(4) {
  position: sticky;
  left: 177px;
  z-index: 1;
  background-color: white;
}
/* 5列目スクロール時固定 */
.v-data-table >>> th:nth-child(5) {
  position: sticky;
  left: 217px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(5) {
  position: sticky;
  left: 217px;
  z-index: 1;
  background-color: white;
}
/* 6列目スクロール時固定 */
.v-data-table >>> th:nth-child(6) {
  position: sticky;
  left: 257px;
  z-index: 3 !important;
  background-color: white;
}
.v-data-table >>> td:nth-child(6) {
  position: sticky;
  left: 257px;
  z-index: 1;
  background-color: white;
}
.carendar-position {
  position: relative;
}
.nameOmit {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.productNameOmit {
  width: 180px;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  line-height: 15px;
}
.productEditIconLayout {
  padding-left: 0;
  padding-bottom: 15px;
}
</style>