<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>商品登録</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- 商品コード -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.productCode"
                      label="商品コード"
                      :hint="`登録すると自動採番されます`"
                      persistent-hint
                      disabled
                      tabindex="100"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 品番 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.productNo"
                      label="品番"
                      :rules="[required, limit_length20, productNo]"
                      counter="20"
                      clearable
                      :disabled="isDisableText"
                      @blur="inputProductName"
                      tabindex="101"
                      ref="focusProductNo"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 型番/商品名 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.productName"
                      label="型番/商品名"
                      :rules="[required, limit_lengthProductName]"
                      counter="40"
                      :counter-value="productCount"
                      clearable
                      :disabled="isDisableText"
                      tabindex="102"
                      ref="focusProductName"
                      :hint="`品番を更新すると、自動で入力されます`"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 略称名 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.productAbbreviation"
                      label="略称名"
                      :rules="[limit_lengthProductAbbreviation]"
                      counter="50"
                      :counter-value="productCount"
                      clearable
                      :disabled="isDisableText"
                      tabindex="103"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- JANコード -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.janCode"
                      label="JANコード"
                      :rules="[limit_length15, code_regex]"
                      counter="15"
                      clearable
                      :disabled="isDisableText"
                      tabindex="104"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 定価 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model.number="currentParams.listPrice"
                      label="定価"
                      :rules="[amountRequired, limit_length8, number]"
                      clearable
                      :disabled="isDisableText"
                      counter="8"
                      :counter-value="numCount"
                      @change="toLocaleString(currentParams.listPrice, 0)"
                      @focus="remove(currentParams.listPrice, 0)"
                      tabindex="105"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 売単価 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model.number="currentParams.salesPrice"
                      label="売単価"
                      :rules="[price]"
                      clearable
                      :disabled="isDisableText"
                      counter="11"
                      :counter-value="numCount"
                      @change="toLocaleString(currentParams.salesPrice, 1)"
                      @focus="remove(currentParams.salesPrice, 1)"
                      tabindex="106"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 仕入単価 -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model.number="currentParams.supplierPrice"
                      label="仕入単価"
                      :rules="[amountRequired, price]"
                      clearable
                      :disabled="isDisableText"
                      counter="11"
                      :counter-value="numCount"
                      @change="toLocaleString(currentParams.supplierPrice, 2)"
                      @focus="remove(currentParams.supplierPrice, 2)"
                      tabindex="107"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 商品分類コード -->
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      v-model="currentParams.productClass"
                      label="商品分類コード"
                      :rules="[required]"
                      :items="productClassList"
                      item-text="comboItem"
                      item-value="code"
                      :disabled="isDisableText"
                      tabindex="108"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- メーカー -->
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="currentParams.productMakerName"
                      label="メーカー"
                      :rules="[limit_length4]"
                      counter="4"
                      clearable
                      :disabled="isDisableText"
                      tabindex="109"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 返品可否 -->
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="currentParams.returnFlg"
                      label="返品可否"
                      mandatory
                      dense
                      :disabled="isDisableText"
                    >
                      <v-radio
                        v-for="returnFlg in returnFlgList"
                        :key="returnFlg.id"
                        :label="returnFlg.name"
                        :value="returnFlg.id"
                        tabindex="110"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <!-- 廃番区分 -->
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="currentParams.discontinuedSection"
                      label="廃番区分"
                      mandatory
                      dense
                      :disabled="isDisableText"
                    >
                      <v-radio
                        v-for="discontinuedSection in discontinuedSectionList"
                        :key="discontinuedSection.id"
                        :label="discontinuedSection.name"
                        :value="discontinuedSection.id"
                        tabindex="111"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <!-- 作成日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.createdDate"
                      label="作成日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 更新日付 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="currentParams.updatedDate"
                      label="更新日付"
                      :hint="`登録すると自動設定されます`"
                      persistent-hint
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- 登録 -->
              <v-btn
                v-if="!transitionFlg"
                color="secondary"
                v-on:click="onClickRegister"
                rounded
                class="my-2"
                :disabled="isDisableText"
                tabindex="112"
              >
                <v-icon left> mdi-database-edit </v-icon>登録
              </v-btn>
              <v-btn
                v-else
                color="secondary"
                v-on:click="onClickRegisterAndReturn"
                rounded
                class="my-2"
                tabindex="113"
              >
                <v-icon left> mdi-database-edit </v-icon>登録して戻る
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import { executeHanbaikanriSql } from "@/graphql/mutations";
import { MANAGER_KIND_LIST } from '@/assets/js/const';
import { CONFIRM_MESSAGE_UNSAVED_DATA, CONFIRM_MESSAGE_CLEAR_DATA, ALERT_MESSAGE_COMMON_ERROR, ALERT_ALREADY_SAVED_DATA ,ALERT_COMPLETE_CRUD_DATA } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "ProductInsertArea",
  props: {
    // 商品登録モーダル：モーダル表示 or 通常の画面遷移
    transitionFlg: Boolean,
    // 商品登録モーダル：入力画面の明細NO、検索画面の発注データID+明細NO
    targetBranchNo: Number,
    // 商品登録モーダル：入力された品番
    insertProductNo: String,
    // 商品登録モーダル：編集を行う商品コード
    editProductCode: Number,
  },
  data: () => ({
    // 変数定義
    currentParams: {
      // 商品コード
      productCode: null,
      // 品番
      productNo: null,
      // 型番/商品名
      productName: null,
      // 略称名
      productAbbreviation: null,
      // JANコード
      janCode: null,
      // 定価
      listPrice: null,
      // 売単価
      salesPrice: null,
      // 仕入単価
      supplierPrice: null,
      // 分類名
      productClass: null,
      // メーカー
      productMakerName: null,
      // 返品可否
      returnFlg: null,
      // 廃番区分
      discontinuedSection: null,
      // 作成日付
      createdDate: null,
      // 更新日付
      updatedDate: null,
    },

    // 商品分類コード
    productClassList: [],

    // ラジオボタン初期設定
    returnFlgList: [
      { id: 0, name: "可" },
      { id: 1, name: "不可" },
    ],
    discontinuedSectionList: [
      { id: 9, name: "通常商品" },
      { id: 1, name: "廃盤商品" },
    ],
    // 新規登録用権限リスト
    managerAuthSectionList: MANAGER_KIND_LIST,
    // 未保存チェック用
    originalParams: {},
    // 項目無効化フラグ
    isDisableText: false,
    // ログインユーザー情報
    loginUserInfo: "",

    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    amountRequired: (value) =>
      (!!`${value}` && value !== null) || "必ず入力してください",
    // 文字数の制約 最大8文字
    limit_length8: (value) =>
      value == null ||
      String(value).replace(/,/g, "").replace(/¥/g, "").length <= 8 ||
      "8文字以内で入力してください",
    // JANコードの制約
    code_regex: (value) =>
      value == null ||
      /^[0-9a-zA-Z-]*$/.test(value) ||
      "半角英数値とハイフンで入力してください",
    // 品番の制約
    productNo: (value) =>
      value == null ||
      (/^[ -~ｦ-ﾟ]*$/).test(value) ||
      "半角で入力してください",
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,]*$/.test(value) || "整数で入力してください",
    // 価格の制約
    price: (value) =>
      value == null ||
      /^([1-9][0-9]{0,7}|0)(\.[0-9]{1,2})?$/.test(
        String(value).replace(/,/g, "").replace(/¥/g, "")
      ) ||
      "整数値8桁、小数値2桁で入力してください",
    // 文字数の制約 最大13文字
    limit_length15: (value) =>
      value == null || value.length <= 15 || "15文字以内で入力してください",
    // 文字数の制約 最大4文字
    limit_length4: (value) =>
      value == null || value.length <= 4 || "4文字以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 文字数の制約 半角40文字、全角20文字
    limit_lengthProductName: value => {
      let len = 0;
      if (value !== null) {
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < value.length; i++) {
          (value[i].match(/[ -~]/)) || (value[i].match(/[ｦ-ﾟ]/))  ? (len += 1) : (len += 2);
        }
      }
      return len <= 40 || `半角40文字、全角20文字以内で入力してください`
    },
    // 文字数の制約 半角50文字、全角25文字
    limit_lengthProductAbbreviation: value => {
      let len = 0;
      if (value !== null) {
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < value.length; i++) {
          (value[i].match(/[ -~]/)) || (value[i].match(/[ｦ-ﾟ]/))  ? (len += 1) : (len += 2);
        }
      }
      return len <= 50 || `半角50文字、全角25文字以内で入力してください`
    },
    // 商品項目（sjis）のカウント
    productCount: (v) => {
      if (v !== null) {
        let len = 0;
        // 半角1文字、全角2文字としてカウント
        for (let i = 0; i < v.length; i++) {
          (v[i].match(/[ -~]/)) || (v[i].match(/[ｦ-ﾟ]/)) ? (len += 1) : (len += 2);
        }
        return len;
      } else {
        return 0;
      }
    },
    // 数値系のカウント
    numCount: (v) => {
      if (v !== null) {
        return `${v}`.replace(/,/g, "").trim().length;
      } else {
        return 0;
      }
    },
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // ローディングの開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // 初期化処理の実行
    if (!(await this.initialize())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 発注入力からの呼び出し時のみ、品番を自動入力
    if (this.transitionFlg && !this.editProductCode) {
      this.currentParams.productNo = this.insertProductNo ? this.insertProductNo : null;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();

    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

    // 未保存チェックを実施し、未保存の場合はエラーを表示
    if (!this.isSavedData()) {
      if (confirm(CONFIRM_MESSAGE_UNSAVED_DATA)) {
        // バリデーションチェックでエラーの際は登録しない
        if (!this.validateCheck()) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }

        // ローディングの開始
        this.$store.commit("setLoading", true);

        // 修正時の判定
        if (
          this.isRequestedUpdate() ||
          typeof this.currentParams.productCode == "number"
        ) {
          // 変更した型番がすでにテーブルに存在する場合、ダイアログを表示
          if (!(await this.searchProductName())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // 修正：入力データで更新
          if (!(await this.updateTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // 画面遷移しない
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
        } else {
          // 型番登録済みの商品の場合、ダイアログを表示
          if (!(await this.searchProductName())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
          // 新規登録：入力データを登録
          if (!(await this.insertTable())) {
            // ローディングを解除
            this.$store.commit("setLoading", false);
            // エラーダイアログを表示
            alert(ALERT_MESSAGE_COMMON_ERROR);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            // 画面遷移しない
            return;
          }
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      }
    }
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return this.loginUserInfo !== null;
    },
    /**
     * 初期化処理
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 検索画面、発注検索（東北商品センター）からの遷移の場合、更新値を取得
      if (this.$route.query.reqProductCode || this.editProductCode) {
        this.reqProductCode = this.$route.query.reqProductCode ? this.$route.query.reqProductCode : this.editProductCode;
        // 商品名にフォーカスを当てる
        this.$refs.focusProductName.focus();
      } else {
        // 品番にフォーカスを当てる
        this.$refs.focusProductNo.focus();
      }
      if (this.isRequestedUpdate()) {
        // 商品コードに紐づくデータを取得
        let sqlList = [
          `SELECT * FROM SHOHIN WHERE SH_SHOCD = ${this.reqProductCode}`,
        ];
        let resultSearchData = await this.executeHanbaikanriSql(sqlList);
        if (resultSearchData[0] == null || resultSearchData[0] == void 0) {
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return false;
        }
        // 結果判定
        for (const data of resultSearchData[0]) {
          this.currentParams = {
            productCode: data.SH_SHOCD,
            productNo: data.SH_SHOKNM,
            productName: data.SH_SHONM,
            productAbbreviation: data.SH_SHORNM,
            janCode: data.SH_JAN,
            listPrice: data.SH_JYOTNA,
            salesPrice: data.SH_URITNA,
            supplierPrice: data.SH_SIRTNA,
            productClass: data.SH_SBUBCD,
            productMakerName: data.SH_MAKERNAME,
            returnFlg: data.SH_HENPINFLG,
            discontinuedSection: data.SH_HAINOKB,
            createdDate: await this.formatDate(data.SH_SAKDT),
            updatedDate: await this.formatDate(data.SH_SYUDT),
          };
          this.toLocaleString(this.currentParams.listPrice, 0);
          this.toLocaleString(this.currentParams.salesPrice, 1);
          this.toLocaleString(this.currentParams.supplierPrice, 2);
        }
      }

      // 販売管理システムの商品基本テーブルから商品分類コードを取得。
      this.productClassList = [];
      let sqlClassificationList = [
        `SELECT SHOBU.HB_SBUCD, SHOBU.HB_SBUNM FROM SHOBU WHERE (SHOBU.HB_SBUCD) > 40000 AND (SHOBU.HB_SBUCD) < 60000 ORDER BY SHOBU.HB_SBUCD`,
      ];
      let resultClassificationData = await this.executeHanbaikanriSql(
        sqlClassificationList
      );
      if (
        resultClassificationData == null ||
        resultClassificationData == void 0
      ) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }

      for (const data of resultClassificationData[0]) {
        if (data.HB_SBUNM == null) {
          // 取得データに商品分類名が存在しない場合、コードのみ表示
          this.productClassList.push({
            comboItem: data.HB_SBUCD,
            code: data.HB_SBUCD,
          });
        } else {
          // 取得データに商品分類名が存在する場合、コード：分類名で表示
          this.productClassList.push({
            comboItem: data.HB_SBUCD + "：" + data.HB_SBUNM,
            code: data.HB_SBUCD,
          });
        }
      }

      // 入力前のデータを保存
      this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 登録ボタン押下処理
     */
    async onClickRegister() {
      // メソッド名を定義
      const method_name="onClickRegister"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は登録しない
      if (!this.validateCheck()) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 保存済みデータの場合、ダイアログを表示
      if (this.isSavedData()) {
        alert(ALERT_ALREADY_SAVED_DATA);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 修正ボタン押下もしくは連続登録しなかった場合
      if (
        (await this.isRequestedUpdate()) ||
        typeof this.currentParams.productCode == "number"
      ) {
        // 変更した型番がすでにテーブルに存在する場合、ダイアログを表示
        if (!(await this.searchProductName())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          return;
        }
        // 修正：入力データで更新
        if (!(await this.updateTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
      } else {
        // 型番登録済みの商品の場合、ダイアログを表示
        if (!(await this.searchProductName())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          return;
        }
        // 新規登録：入力データを登録
        if (!(await this.insertTable())) {
          // ローディングを解除
          this.$store.commit("setLoading", false);
          // エラーダイアログを表示
          alert(ALERT_MESSAGE_COMMON_ERROR);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return;
        }
        // ローディングを解除
        this.$store.commit("setLoading", false);
        console.log(
          "currentParams = " + JSON.stringify(this.currentParams)
        );
        // 完了ダイアログを表示
        alert(ALERT_COMPLETE_CRUD_DATA);
        if (this.transitionFlg) {
          return true;
        }
        if (confirm(CONFIRM_MESSAGE_CLEAR_DATA)) {
          // 連続登録の場合
          // ローディングの開始
          this.$store.commit("setLoading", true);
          // 画面上のデータを初期化
          this.currentParams.productCode = null;
          this.currentParams.productNo = null;
          this.currentParams.productName = null;
          this.currentParams.productAbbreviation = null;
          this.currentParams.janCode = null;
          this.currentParams.listPrice = null;
          this.currentParams.salesPrice = null;
          this.currentParams.supplierPrice = null;
          this.currentParams.productClass = null;
          this.currentParams.productMakerName = null;
          this.currentParams.returnFlg = null;
          this.currentParams.discontinuedSection = null;
          this.currentParams.createdDate = null;
          this.currentParams.updatedDate = null;

          // 未保存チェック用配列を更新
          this.saveOriginalParams();

          // バリデーションエラーをリセット
          this.$refs.form.resetValidation();
        } else {
          if (await this.authorityCheck()) {
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return;
          }
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },

    /**
     * 新規登録処理
     */
    async insertTable() {
      // メソッド名を定義
      const method_name="insertTable"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // コード最大値の取得
      let currentMaxCode;
      // 検索条件
      let sqlList = [
        `SELECT SHOHIN.SH_SHOCD FROM SHOHIN ORDER BY SHOHIN.SH_SHOCD DESC FETCH FIRST 1 ROWS ONLY`,
      ];

      // 販売管理用Lambdaの実行
      let resultData = await this.executeHanbaikanriSql(sqlList);
      if (resultData[0] == null || resultData[0] == void 0) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 取得した最大値の取得
      currentMaxCode = resultData[0][0].SH_SHOCD;

      // DB登録用データを整形する
      currentMaxCode = Number(currentMaxCode + 1);
      this.remove(this.currentParams.listPrice, 0);
      this.remove(this.currentParams.salesPrice, 1);
      this.remove(this.currentParams.supplierPrice, 2);
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, "0") +
        String(date.getDate()).padStart(2, "0");

      // DBに入力データ登録
      // 検索条件
      let productAbbreviation =
        this.currentParams.productAbbreviation == null
          ? null
          : `'${this.currentParams.productAbbreviation}'`;
      let janCode =
        this.currentParams.janCode == null
          ? null
          : `'${this.currentParams.janCode}'`;
      let productMakerName =
        this.currentParams.productMakerName == null
          ? null
          : `'${this.currentParams.productMakerName}'`;

      let sqlInsList = [
        `INSERT INTO SHOHIN (SH_SHOCD, SH_SHOKNM, SH_SHONM, SH_SHORNM, SH_JAN, SH_JYOTNA, SH_URITNA, SH_SIRTNA, SH_FRITNA, SH_ZAITNA, SH_SBUBCD, SH_MAKERNAME, SH_HENPINFLG, SH_HAINOKB, ` +
        `SH_RANATNA, SH_RANBTNA, SH_RANCTNA, SH_RANDTNA, SH_RANETNA, SH_HEKDT, SH_URITNB, SH_SIRTNB, SH_ZAITNB, SH_JYOTNB, SH_FRITNB, SH_RANATNB, SH_RANBTNB, SH_RANCTNB, SH_RANDTNB, SH_RANETNB, ` +
        `SH_IRISU, SH_SIRCD, SH_HACHUTEN, SH_HACHUMIN, SH_MTANCD, SH_STANCD, SH_SKBUCD, SH_RSHOKB, SH_RSHOCD, SH_KG, SH_MEKCD, SH_HOWCD, SH_TAXKB, SH_TNSIYOKB, SH_ZAIKANKB, SH_TORIYOKB, SH_YUNYUKB,  ` +
        `SH_KBN1, SH_KBN2, SH_KBN3, SH_KBN4, SH_KBN5, SH_ENDINDT, SH_ENDOTDT, SH_SAKDT, SH_SYUDT) VALUES (` +
          `${currentMaxCode},` + // SH_SHOCD
          `'${this.currentParams.productNo}',` + // SH_SHOKNM
          `'${this.currentParams.productName}',` + // SH_SHONM
          `${productAbbreviation},` + // SH_SHORNM
          `${janCode},` + // SH_JAN
          `${this.currentParams.listPrice},` + // SH_JYOTNA
          `${this.currentParams.salesPrice ?? 0},` + // SH_URITNA
          `${this.currentParams.supplierPrice},` + // SH_SIRTNA
          `${this.currentParams.supplierPrice},` + // SH_FRITNA
          `${this.currentParams.supplierPrice},` + // SH_ZAITNA
          `${this.currentParams.productClass},` + // SH_SBUBCD
          `${productMakerName},` + // SH_MAKERNAME
          `${this.currentParams.returnFlg},` + // SH_HENPINFLG
          `${this.currentParams.discontinuedSection},` + // SH_HAINOKB
          '0,' + // SH_RANATNA
          '0,' + // SH_RANBTNA
          '0,' + // SH_RANCTNA
          '0,' + // SH_RANDTNA
          '0,' + // SH_RANETNA
          '0,' + // SH_HEKDT
          '0,' + // SH_URITNB
          '0,' + // SH_SIRTNB
          '0,' + // SH_ZAITNB
          '0,' + // SH_JYOTNB
          '0,' + // SH_FRITNB
          '0,' + // SH_RANATNB
          '0,' + // SH_RANBTNB
          '0,' + // SH_RANCTNB
          '0,' + // SH_RANDTNB
          '0,' + // SH_RANETNB
          '0,' + // SH_IRISU
          '0,' + // SH_SIRCD
          '0,' + // SH_HACHUTEN
          '0,' + // SH_HACHUMIN
          '0,' + // SH_MTANCD
          '0,' + // SH_STANCD
          '0,' + // SH_SKBUCD
          '0,' + // SH_RSHOKB
          '0,' + // SH_RSHOCD
          '0,' + // SH_KG
          '0,' + // SH_MEKCD
          '0,' + // SH_HOWCD
          '0,' + // SH_TAXKB
          '0,' + // SH_TNSIYOKB
          '0,' + // SH_ZAIKANKB
          '0,' + // SH_TORIYOKB
          '0,' + // SH_YUNYUKB
          '0,' + // SH_KBN1
          '0,' + // SH_KBN2
          '0,' + // SH_KBN3
          '0,' + // SH_KBN4
          '0,' + // SH_KBN5
          '0,' + // SH_ENDINDT
          '0,' + // SH_ENDOTDT
          `${sysdate},` + // SH_SAKDT
          `${sysdate})`, // SH_SYUDT
        `SELECT SHOHIN.SH_SHOCD, SHOHIN.SH_SHOKNM, SHOHIN.SH_SHONM, SHOHIN.SH_SHORNM, SHOHIN.SH_JAN, SHOHIN.SH_JYOTNA, SHOHIN.SH_URITNA, SHOHIN.SH_SIRTNA, SHOHIN.SH_FRITNA, SHOHIN.SH_ZAITNA, SHOHIN.SH_SBUBCD, SHOHIN.SH_MAKERNAME, SHOHIN.SH_HENPINFLG, SHOHIN.SH_HAINOKB, SHOHIN.SH_SAKDT, SHOHIN.SH_SYUDT FROM SHOHIN WHERE SH_SHOCD = ${currentMaxCode}`,
      ];
      // 販売管理用Lambdaの実行
      let resultReturnData = await this.executeHanbaikanriSql(sqlInsList);
      if (resultReturnData[0][0] == null || resultReturnData[0][0] == void 0) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 結果判定
      // 更新したデータで画面データを更新
      let strDate = String(resultReturnData[0][0].SH_SAKDT);
      let strSH_SAKDT = `${strDate.substr(0, 4)}-${strDate.substr(
        4,
        2
      )}-${strDate.substr(6, 2)}`;
      strDate = String(resultReturnData[0][0].SH_SYUDT);
      let strSH_SYUDT = `${strDate.substr(0, 4)}-${strDate.substr(
        4,
        2
      )}-${strDate.substr(6, 2)}`;

      this.currentParams.productCode = resultReturnData[0][0].SH_SHOCD;
      this.currentParams.productNo = resultReturnData[0][0].SH_SHOKNM;
      this.currentParams.productName = resultReturnData[0][0].SH_SHONM;
      this.currentParams.productAbbreviation = resultReturnData[0][0].SH_SHORNM;
      this.currentParams.janCode = resultReturnData[0][0].SH_JAN;
      this.currentParams.listPrice = resultReturnData[0][0].SH_JYOTNA;
      this.currentParams.salesPrice = resultReturnData[0][0].SH_URITNA;
      this.currentParams.supplierPrice = resultReturnData[0][0].SH_SIRTNA;
      this.currentParams.productClass = resultReturnData[0][0].SH_SBUBCD;
      this.currentParams.productMakerName = resultReturnData[0][0].SH_MAKERNAME;
      this.currentParams.returnFlg = resultReturnData[0][0].SH_HENPINFLG;
      this.currentParams.discontinuedSection =
        resultReturnData[0][0].SH_HAINOKB;
      this.currentParams.createdDate = strSH_SAKDT;
      this.currentParams.updatedDate = strSH_SYUDT;

      // 金額をカンマ区切りにする
      await this.toLocaleString(this.currentParams.listPrice, 0);
      await this.toLocaleString(this.currentParams.salesPrice, 1);
      await this.toLocaleString(this.currentParams.supplierPrice, 2);

      // 未保存チェック用配列を更新
      await this.saveOriginalParams();
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 修正処理
     */
    async updateTable() {
      // メソッド名を定義
      const method_name="updateTable"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // データ取得
      let date = new Date();
      let sysdate =
        date.getFullYear() +
        String(date.getMonth() + 1).padStart(2, "0") +
        String(date.getDate()).padStart(2, "0");
      this.remove(this.currentParams.listPrice, 0);
      this.remove(this.currentParams.salesPrice, 1);
      this.remove(this.currentParams.supplierPrice, 2);

      // DBに入力データ登録
      // 検索条件
      let productAbbreviation =
        this.currentParams.productAbbreviation == null
          ? null
          : `'${this.currentParams.productAbbreviation}'`;
      let janCode =
        this.currentParams.janCode == null
          ? null
          : `'${this.currentParams.janCode}'`;
      let productMakerName =
        this.currentParams.productMakerName == null
          ? null
          : `'${this.currentParams.productMakerName}'`;
      let sqlUpdList = [
        `UPDATE SHOHIN SET` +
          ` SH_SHOKNM = '${this.currentParams.productNo}',` +
          ` SH_SHONM = '${this.currentParams.productName}',` +
          ` SH_SHORNM = ${productAbbreviation},` +
          ` SH_JAN = ${janCode},` +
          ` SH_JYOTNA = ${this.currentParams.listPrice},` +
          ` SH_URITNA = ${this.currentParams.salesPrice},` +
          ` SH_SIRTNA = ${this.currentParams.supplierPrice},` +
          ` SH_FRITNA = ${this.currentParams.supplierPrice},` +
          ` SH_ZAITNA = ${this.currentParams.supplierPrice},` +
          ` SH_SBUBCD = ${this.currentParams.productClass},` +
          ` SH_MAKERNAME = ${productMakerName},` +
          ` SH_HENPINFLG = ${this.currentParams.returnFlg},` +
          ` SH_HAINOKB = ${this.currentParams.discontinuedSection},` +
          ` SH_SYUDT = ${sysdate}` +
          ` WHERE SH_SHOCD = ${this.currentParams.productCode}`,
        `SELECT SH_SHOCD, SH_SHOKNM, SH_SHONM, SH_SHORNM, SH_JAN, SH_JYOTNA, SH_URITNA, SH_SIRTNA, SH_FRITNA, SH_ZAITNA, SH_SBUBCD, SH_MAKERNAME, SH_HENPINFLG, SH_HAINOKB, SH_SAKDT, SH_SYUDT FROM SHOHIN WHERE SH_SHOCD = ${this.currentParams.productCode}`,
      ];

      let resultData = await this.executeHanbaikanriSql(sqlUpdList);
      if (resultData[0][0] == null || resultData[0][0] == void 0) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }

      // 更新したデータで画面データを更新
      this.currentParams.productCode = resultData[0][0].SH_SHOCD;
      this.currentParams.productNo = resultData[0][0].SH_SHOKNM;
      this.currentParams.productName = resultData[0][0].SH_SHONM;
      this.currentParams.productAbbreviation = resultData[0][0].SH_SHORNM;
      this.currentParams.janCode = resultData[0][0].SH_JAN;
      this.currentParams.listPrice = resultData[0][0].SH_JYOTNA;
      this.currentParams.salesPrice = resultData[0][0].SH_URITNA;
      this.currentParams.supplierPrice = resultData[0][0].SH_SIRTNA;
      this.currentParams.productClass = resultData[0][0].SH_SBUBCD;
      this.currentParams.productMakerName = resultData[0][0].SH_MAKERNAME;
      this.currentParams.returnFlg = resultData[0][0].SH_HENPINFLG;
      this.currentParams.discontinuedSection = resultData[0][0].SH_HAINOKB;
      this.currentParams.createdDate = await this.formatDate(resultData[0][0].SH_SAKDT);
      this.currentParams.updatedDate = await this.formatDate(resultData[0][0].SH_SYUDT);

      // 金額をカンマ区切りにする
      await this.toLocaleString(this.currentParams.listPrice, 0);
      await this.toLocaleString(this.currentParams.salesPrice, 1);
      await this.toLocaleString(this.currentParams.supplierPrice, 2);
      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },

    /**
     * 未保存チェック用比較データの更新
     */
    saveOriginalParams() {
      // メソッド名を定義
      const method_name="saveOriginalParams"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 保存時の入力データを未保存チェック時の比較データとして更新
      this.originalParams = JSON.parse(JSON.stringify(this.currentParams));

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 未保存チェック
     */
    isSavedData() {
      // メソッド名を定義
      const method_name="isSavedData"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);

      return (
        JSON.stringify(this.currentParams) ==
        JSON.stringify(this.originalParams)
      );
    },
    /**
     * 既存データの更新チェック
     */
    isRequestedUpdate() {
      // メソッド名を定義
      const method_name="isRequestedUpdate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);

      return this.reqProductCode !== void 0;
    },
    /**
     * バリデーションチェック
     */
    validateCheck() {
      // メソッド名を定義
      const method_name="validateCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // バリデーションチェックでエラーの際は登録しない
      const form = this.$refs.form;
      if (!form.validate()) {
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * ユーザー権限のチェック
     */
    authorityCheck() {
      // メソッド名を定義
      const method_name="authorityCheck"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 画面の無効化
      let loginUser = this.managerAuthSectionList.find(
        (items) => items.code == this.$store.getters.user.TANTOSHA_AUTH_KBN
      );
      this.isDisableText = (loginUser && !loginUser.isAuthUser);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 数値を3桁カンマ区切りにする
     */
    async toLocaleString(val, id) {
      // メソッド名を定義
      const method_name="toLocaleString"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 入力値がなければメソッドを抜ける
      if (!val || isNaN(val)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      var trimVal;
      // 既にカンマが入力されていたら事前に削除
      String(val).match(",")
        ? (trimVal = String(val).replace(/,/g, "").trim())
        : (trimVal = val);

      // 整数部分と小数部分に分割
      var numData = trimVal.toString().split(".");
      // 整数部分を3桁カンマ区切りへ
      numData[0] = Number(numData[0])
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // 小数部分と結合
      if (numData[1]) {
        numData = numData[0] + "." + numData[1];
      }
      // 値を返却する
      switch (id) {
        case 0:
          this.currentParams.listPrice = numData;
          break;
        case 1:
          this.currentParams.salesPrice = numData;
          break;
        case 2:
          this.currentParams.supplierPrice = numData;
          break;
        default:
          break;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * カンマ区切りの値を数値にする
     */
    remove(val, id) {
      // メソッド名を定義
      const method_name="remove"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 入力されていない場合、文字列が入力されている場合は何もしない
      var numData = String(val).replace(/,/g, "").replace(/¥/g, "");
      if (!val || isNaN(numData)) {
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      numData = Number(numData);
      switch (id) {
        case 0:
          this.currentParams.listPrice = numData;
          break;
        case 1:
          this.currentParams.salesPrice = numData;
          break;
        case 2:
          this.currentParams.supplierPrice = numData;
          break;
        default:
          break;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 販売管理システム用関数の実行処理
     */
    async executeHanbaikanriSql(sqlList) {
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let condition = { SQLs: sqlList };
      console.log(
        "executeHanbaikanriSql：condition = " + JSON.stringify(condition)
      );
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            console.log("executeHanbaikanriSql：error");
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return null;
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, { depth: null });
              }
            }
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            console.log("executeHanbaikanriSql：SUCCESS ");
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return responseBody.data;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          console.log("executeHanbaikanriSql : " + JSON.stringify(result));
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return null;
        }
      } catch (error) {
        console.log(
          "executeHanbaikanriSql : 異常終了 = " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return null;
      }
    },
    /**
     * 型番の存在チェック
     */
    async searchProductName(){
      // メソッド名を定義
      const method_name="searchProductName"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let sqlSearchList = [];
      // 更新の場合
      if (this.currentParams.productCode) {
          sqlSearchList = [
          `SELECT SH_SHONM FROM SHOHIN WHERE SH_SHOCD <> '${this.currentParams.productCode}'` +
          ` AND SH_SHOKNM = '${this.currentParams.productNo}'`,
        ];
      } else {
        // 登録の場合
        sqlSearchList = [
          `SELECT SH_SHOKNM FROM SHOHIN WHERE SH_SHOKNM = '${this.currentParams.productNo}'`,
        ];
      }
      let resultSearchData = await this.executeHanbaikanriSql(sqlSearchList);
      if (resultSearchData == null) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // 同じ型番がすでに登録されている場合
      if (resultSearchData[0][0]) {
        alert("入力した品番はすでに登録されています。");
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * 型番の存在チェック
     */
    async formatDate (date) {
      // メソッド名を定義
      const method_name="formatDate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      date = String(date);
      let parseDate;
      parseDate = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return parseDate;
    },
    /**
     * 品番入力時の型番／商品名の自動入力処理
     */
    async inputProductName() {
      // メソッド名を定義
      const method_name="inputProductName"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      if (this.currentParams.productNo !== null && this.currentParams.productNo !== "") {
        // 半角英数字を全角英数字に変換
        this.currentParams.productName = this.currentParams.productNo.replace(/[A-Za-z0-9!-/:-@¥[-`{-~]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
        });

        let kanaMap = {
            'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
            'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
            'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
            'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
            'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
            'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
            'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
            'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
            'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
            'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
            'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
            'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
            'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
            'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
            'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
            'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
            'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
            'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
            '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
        };
        // 半角カタカナを全角カタカナに変換
        var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
        this.currentParams.productName = this.currentParams.productName.replace(reg, function (match) {
            return kanaMap[match];
        }).replace(/ﾞ/g, '゛').replace(/ﾟ/g, '゜');
      }

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 「保存し入力に戻る」ボタン押下時処理
     */
    async onClickRegisterAndReturn() {
      // メソッド名を定義
      const method_name="onClickRegisterAndReturn"
      // オペレーションタイプを定義
      const operation_type="0002"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // 商品の登録処理
      if(!await this.onClickRegister()) return;

      console.log("this.targetBranchNo = " + this.targetBranchNo + ", this.currentParams.productCode = " + this.currentParams.productCode)
      // 入力画面に登録した商品コードを渡す
      this.$emit("result", {
        returnNo: this.targetBranchNo,
        returnProductCode: this.currentParams.productCode,
      });

      // 画面上のデータクリア
      this.currentParams.productCode = null;
      this.currentParams.productNo = null;
      this.currentParams.productName = null;
      this.currentParams.productAbbreviation = null;
      this.currentParams.janCode = null;
      this.currentParams.listPrice = null;
      this.currentParams.salesPrice = null;
      this.currentParams.supplierPrice = null;
      this.currentParams.productClass = null;
      this.currentParams.productMakerName = null;
      this.currentParams.returnFlg = null;
      this.currentParams.discontinuedSection = null;
      this.currentParams.createdDate = null;
      this.currentParams.updatedDate = null;

      // 未保存チェック用配列を更新
      this.saveOriginalParams();

      // バリデーションエラーをリセット
      this.$refs.form.resetValidation();

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
  },
};
</script>